import { Card } from "@/components/ui/card";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const memberActivityData = [
  { name: "Mon", Alice: 4, Bob: 3, Charlie: 2, David: 4 },
  { name: "Tue", Alice: 3, Bob: 4, Charlie: 3, David: 3 },
  { name: "Wed", Alice: 2, Bob: 4, Charlie: 4, David: 2 },
  { name: "Thu", Alice: 4, Bob: 3, Charlie: 3, David: 4 },
  { name: "Fri", Alice: 3, Bob: 2, Charlie: 4, David: 3 },
];

export function MemberActivityChart() {
  return (
    <Card className="p-6">
      <h2 className="text-xl font-semibold mb-4">Member Activity</h2>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={memberActivityData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis 
              dataKey="name" 
              axisLine={false}
              tickLine={false}
              dy={10}
            />
            <YAxis 
              axisLine={false}
              tickLine={false}
              dx={-10}
            />
            <Tooltip 
              contentStyle={{
                background: '#fff',
                border: '1px solid #e2e8f0',
                borderRadius: '6px',
                padding: '8px',
              }}
            />
            <Legend />
            <Line
              type="monotone"
              dataKey="Alice"
              stroke="#8b5cf6"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Bob"
              stroke="#6366f1"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="Charlie"
              stroke="#ec4899"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="David"
              stroke="#14b8a6"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}