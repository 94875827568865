import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Mail, MessageSquare, Info } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Footer } from "./components/Footer";

const Support = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center space-y-4 mb-12">
          <h1 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600">
            {`We're Here to Help`}
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Get the support you need through our various channels. Our team is
            ready to assist you.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {/* Email Support */}
          <Card className="group hover:shadow-lg transition-all duration-300">
            <CardContent className="p-6 text-center space-y-4">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto group-hover:scale-110 transition-transform duration-300">
                <Mail className="w-6 h-6 text-purple-600" />
              </div>
              <h3 className="text-xl font-bold">Email Support</h3>
              <p className="text-gray-600">Reach out to us via email</p>
              <a
                href="mailto:team@getworkfolio.com"
                className="inline-block text-purple-600 hover:text-purple-700 font-medium"
              >
                team@getworkfolio.com
              </a>
            </CardContent>
          </Card>

          {/* Chat Support */}
          <Card className="group hover:shadow-lg transition-all duration-300">
            <CardContent className="p-6 text-center space-y-4">
              <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto group-hover:scale-110 transition-transform duration-300">
                <MessageSquare className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-bold">Live Chat</h3>
              <p className="text-gray-600">Chat with our support team</p>
              <a
                href="https://tawk.to/workfolio"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-blue-600 hover:text-blue-700 font-medium"
              >
                Open Live Chat
              </a>
            </CardContent>
          </Card>

          {/* Knowledge Base */}
          <Card className="group hover:shadow-lg transition-all duration-300">
            <CardContent className="p-6 text-center space-y-4">
              <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto group-hover:scale-110 transition-transform duration-300">
                <Info className="w-6 h-6 text-green-600" />
              </div>
              <h3 className="text-xl font-bold">Knowledge Base</h3>
              <p className="text-gray-600">Browse our help articles</p>
              <a
                href="https://docs.lovable.dev/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-green-600 hover:text-green-700 font-medium"
              >
                Visit Knowledge Base
              </a>
            </CardContent>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Support;
