import { useEffect } from "react";
import { Hero } from "./components/Hero";
import { Benefits } from "./components/Benefits";
import { Features } from "./components/Features";
import { Testimonial } from "./components/Testimonial";
import { FreePricing } from "./components/FreePricing";
import { HowItWorks } from "./components/HowItWorks";
import { Footer } from "./components/Footer";
import "./landingPage.css";
import { useNavigate } from "react-router-dom";
import { ImpactMetrics } from "./components/ImpactMetrics";

const LandingPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("visible");
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    });

    document.querySelectorAll(".scroll-reveal").forEach((element) => {
      observer.observe(element);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <main className="min-h-screen">
      <Hero />
      <ImpactMetrics />
      <Benefits />
      <Features />
      <HowItWorks />
      <Testimonial />
      <FreePricing />
      <Footer />
    </main>
  );
};

export default LandingPage;
