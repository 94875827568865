import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectLabel,
} from "@/components/ui/select";
import MultipleSelector from "@/components/ui/multi-selector";
import { useCallback, useState, useMemo } from "react";

interface ReminderFields {
  content: string;
  time: string;
  timeZone: string;
  frequency: string;
  days: string[];
  participants?: string[];
}

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const frequencies = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "biweekly", label: "Two Weeks Once" },
  { value: "monthly", label: "Monthly" },
  { value: "once", label: "One Time" },
];

const timeZones = {
  "North America": [
    { value: "est", label: "Eastern Standard Time (EST)" },
    { value: "cst", label: "Central Standard Time (CST)" },
    { value: "mst", label: "Mountain Standard Time (MST)" },
    { value: "pst", label: "Pacific Standard Time (PST)" },
    { value: "akst", label: "Alaska Standard Time (AKST)" },
    { value: "hst", label: "Hawaii Standard Time (HST)" },
  ],
  "Europe & Africa": [
    { value: "gmt", label: "Greenwich Mean Time (GMT)" },
    { value: "cet", label: "Central European Time (CET)" },
    { value: "eet", label: "Eastern European Time (EET)" },
    { value: "west", label: "Western European Summer Time (WEST)" },
    { value: "cat", label: "Central Africa Time (CAT)" },
    { value: "eat", label: "East Africa Time (EAT)" },
  ],
  Asia: [
    { value: "msk", label: "Moscow Time (MSK)" },
    { value: "ist", label: "India Standard Time (IST)" },
    { value: "cst_china", label: "China Standard Time (CST)" },
    { value: "jst", label: "Japan Standard Time (JST)" },
    { value: "kst", label: "Korea Standard Time (KST)" },
    { value: "ist_indonesia", label: "Indonesia Central Standard Time (WITA)" },
  ],
  "Australia & Pacific": [
    { value: "awst", label: "Australian Western Standard Time (AWST)" },
    { value: "acst", label: "Australian Central Standard Time (ACST)" },
    { value: "aest", label: "Australian Eastern Standard Time (AEST)" },
    { value: "nzst", label: "New Zealand Standard Time (NZST)" },
    { value: "fjt", label: "Fiji Time (FJT)" },
  ],
  "South America": [
    { value: "art", label: "Argentina Time (ART)" },
    { value: "bot", label: "Bolivia Time (BOT)" },
    { value: "brt", label: "Brasilia Time (BRT)" },
    { value: "clt", label: "Chile Standard Time (CLT)" },
  ],
};

interface ReminderFieldsProps {
  fields: ReminderFields;
  onFieldsChange: (fields: ReminderFields) => void;
  availableUsers: { value: string; label: string }[];
}

export function ReminderFields({
  fields,
  onFieldsChange,
  availableUsers,
}: ReminderFieldsProps) {
  const handleChange = useCallback(
    (
      field: keyof ReminderFields,
      value: ReminderFields[keyof ReminderFields]
    ) => {
      const updatedFields = { ...fields, [field]: value };
      onFieldsChange(updatedFields);
    },
    [fields, onFieldsChange]
  );

  const [selectedDays, setSelectedDays] = useState<string[]>(fields.days || []);

  const toggleDay = (day: string) => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];
    setSelectedDays(updatedDays);
    onFieldsChange({ ...fields, days: updatedDays });
  };

  const [timeZone, setTimeZone] = useState("");

  const filteredTimeZones: Record<string, { value: string; label: string }[]> =
    useMemo(() => {
      return Object.entries(timeZones).reduce((acc: any, [region, zones]) => {
        const filteredZones = zones.filter((zone) =>
          zone.label.toLowerCase().includes(timeZone.toLowerCase())
        );
        if (filteredZones.length > 0) acc[region] = filteredZones;
        return acc;
      }, {} as typeof timeZones);
    }, [timeZone]);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="content">Content to Remind</Label>
        <Textarea
          id="content"
          value={fields.content}
          onChange={(e) => handleChange("content", e.target.value)}
          placeholder="Enter reminder content"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="time">Time of Trigger</Label>
        <Input
          id="time"
          type="time"
          value={fields.time}
          onChange={(e) => handleChange("time", e.target.value)}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="timeZone">Timezone</Label>
        <Select
          value={fields.timeZone || ""}
          onValueChange={(timeZone) => handleChange("timeZone", timeZone)}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder={fields.timeZone || "Select a timeZone"} />
          </SelectTrigger>
          <SelectContent className="max-h-[320px] overflow-auto">
            <div className="p-2">
              <Input
                placeholder="Search time zones..."
                value={timeZone || ""}
                onChange={(e) => setTimeZone(e.target.value)}
                className="w-full mb-2"
              />
            </div>
            {Object.entries(filteredTimeZones).map(([region, zones]) => (
              <SelectGroup key={region}>
                <SelectLabel>{region}</SelectLabel>
                {zones.map((zone) => (
                  <SelectItem key={zone.value} value={zone.value}>
                    {zone.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label>Frequency</Label>
        <Select
          value={fields.frequency}
          onValueChange={(value) => handleChange("frequency", value)}
        >
          <SelectTrigger>
            <SelectValue placeholder={fields.frequency || "Select frequency"} />
          </SelectTrigger>
          <SelectContent>
            {frequencies.map((freq) => (
              <SelectItem
                key={freq.value}
                value={freq.value}
                className={fields.frequency === freq.value ? "font-bold" : ""}
              >
                {freq.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div>
        <Label>Days</Label>
        <div className="flex flex-wrap gap-2 mt-2">
          {daysOfWeek.map((day) => (
            <Button
              key={day}
              type="button"
              variant={
                selectedDays.includes(day.toLowerCase()) ? "default" : "outline"
              }
              onClick={() => toggleDay(day.toLowerCase())}
              className={
                selectedDays.includes(day.toLowerCase())
                  ? "bg-[#9b87f5] hover:bg-[#7E69AB] text-white"
                  : "bg-[#F2FCE2] text-[#8E9196] hover:bg-[#D6BCFA]"
              }
            >
              {day.slice(0, 3)}
            </Button>
          ))}
        </div>
      </div>

      <div>
        <Label>Select Participants</Label>
        <MultipleSelector
          options={availableUsers}
          value={availableUsers.filter((option) =>
            fields.participants?.includes(option.value)
          )}
          onChange={(selectedOptions) => {
            const participants = selectedOptions.map((opt) => opt.value);
            handleChange("participants", participants);
          }}
          placeholder="Select participants"
        />
      </div>
    </div>
  );
}
