import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { AppSidebar } from "./components/AppSidebar";
import { Toaster } from "./components/ui/sonner";
import { SidebarProvider } from "./components/ui/sidebar";
import Index from "./pages/home/Index";
import CreateStandup from "./pages/CreateStandup";
import CreateReminder from "./pages/CreateReminder";
import CreateCheckIn from "./pages/CreateCheckIn";
import Standups from "./pages/Standups";
import Reminders from "./pages/Reminders";
import TeamCheckIns from "./pages/TeamCheckIns";
import TeamMembers from "./pages/TeamMembers";
import Analytics from "./pages/Analytics";
import Settings from "./pages/Settings";
import Billing from "./pages/Billing";
import SlackCommands from "./pages/SlackCommands";
import LandingPage from "./components/LandingPage/Landing";
import Pricing from "./components/LandingPage/Pricing";
import Features from "./components/LandingPage/Features";
import Blog from "./components/LandingPage/Blog";
import Support from "./components/LandingPage/Support";
import AboutUs from "./components/LandingPage/AboutUs";
import Contact from "./components/LandingPage/Contacts";
import Login from "./pages/Login";
//Test Push 2
function AppLayout() {
  const location = useLocation();

  // List of routes that should not have the sidebar
  const noSidebarRoutes = [
    "/",
    "/pricing",
    "/features",
    "/blog",
    "/support",
    "/about",
    "/contact",
    "/login",
  ];
  const isNoSidebarRoute = noSidebarRoutes.includes(location.pathname);

  return (
    <>
      {/* checking for sidebar  */}
      {!isNoSidebarRoute ? (
        // if sidebar is present
        //  add the sidebar to the app
        <SidebarProvider>
          <div className="flex min-h-screen w-full">
            <AppSidebar />
            <main className="flex-1 bg-gray-50">
              <Routes>
                <Route path="/home" element={<Index />} />
                <Route path="/create-standup" element={<CreateStandup />} />
                <Route path="/create-reminder" element={<CreateReminder />} />
                <Route path="/create-check-in" element={<CreateCheckIn />} />
                <Route path="/standups" element={<Standups />} />
                <Route path="/reminders" element={<Reminders />} />
                <Route path="/team-check-ins" element={<TeamCheckIns />} />
                <Route path="/team-members" element={<TeamMembers />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/slack-commands" element={<SlackCommands />} />
              </Routes>
            </main>
          </div>
          <Toaster />
        </SidebarProvider>
      ) : (
        // if sidebar is not present
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/features" element={<Features />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/support" element={<Support />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
    </>
  );
}

// exporting the app
function App() {
  return (
    <Router>
      {/* rendering the app */}
      <AppLayout />
    </Router>
  );
}

export default App;
