import { Clock, TrendingDown, Zap } from "lucide-react";

export const ImpactMetrics = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4">
            Workfolio Bot{" "}
            <span className="relative">
              <span className="relative z-10">simplifies</span>
              <span className="absolute bottom-1 left-0 w-full h-3 bg-yellow-300/70 -rotate-2 rounded-sm"></span>
            </span>{" "}
            team communication
          </h2>
          <p className="text-xl text-gray-600 mb-16">
            {`Workfolio Bot boosted a 60-person agency's productivity by automating tasks.`}
          </p>

          <div className="grid md:grid-cols-3 gap-8">
            <div className="glass-card p-8 rounded-2xl text-center hover-lift">
              <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-yellow-100">
                <Clock className="w-8 h-8 text-yellow-500" />
              </div>
              <div className="text-4xl font-bold mb-2">12 hrs</div>
              <div className="text-gray-600">
                Saved weekly on meeting coordination
              </div>
            </div>

            <div className="glass-card p-8 rounded-2xl text-center hover-lift">
              <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-green-100">
                <TrendingDown className="w-8 h-8 text-green-500" />
              </div>
              <div className="text-4xl font-bold mb-2">-40%</div>
              <div className="text-gray-600">
                Reduction in meeting time across teams
              </div>
            </div>

            <div className="glass-card p-8 rounded-2xl text-center hover-lift">
              <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-purple-100">
                <Zap className="w-8 h-8 text-purple-500" />
              </div>
              <div className="text-4xl font-bold mb-2">90%</div>
              <div className="text-gray-600">
                Faster updates on team progress
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
