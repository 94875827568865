export interface WorkEvent {
  id: number;
  userId: string;
  userName: string;
  eventType: string;
  timestamp: Date;
}

export const workEvents: WorkEvent[] = [
  {
    id: 1,
    userId: "U123",
    userName: "John Doe",
    eventType: "work_start",
    timestamp: new Date("2024-01-23T09:00:00"),
  },
  {
    id: 2,
    userId: "U123",
    userName: "John Doe",
    eventType: "break_start",
    timestamp: new Date("2024-01-23T12:00:00"),
  },
  {
    id: 3,
    userId: "U123",
    userName: "John Doe",
    eventType: "break_end",
    timestamp: new Date("2024-01-23T13:00:00"),
  },
  {
    id: 4,
    userId: "U123",
    userName: "John Doe",
    eventType: "work_end",
    timestamp: new Date("2024-01-23T17:00:00"),
  },
  {
    id: 5,
    userId: "U124",
    userName: "Jane Smith",
    eventType: "work_start",
    timestamp: new Date("2024-01-23T08:30:00"),
  },
  {
    id: 6,
    userId: "U124",
    userName: "Jane Smith",
    eventType: "work_end",
    timestamp: new Date("2024-01-23T16:30:00"),
  },
];