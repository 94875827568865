import { Card, CardContent } from "@/components/ui/card";
import { Footer } from "./components/Footer";
import {
  Users,
  Globe,
  Building,
  MapPin,
  Sparkles,
  History,
  Target,
} from "lucide-react";

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Hero Section */}
        <div className="text-center space-y-6 mb-20 animate-fade-in">
          <h1 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#9b87f5] to-[#7E69AB] leading-tight">
            The Story Behind
            <br />
            Workfolio Bot
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            From employee productivity monitoring to revolutionizing team
            meetings - our journey of innovation and growth.
          </p>
        </div>

        {/* Timeline Section */}
        <div className="mb-20">
          <Card className="hover:shadow-xl transition-all duration-300 border-none shadow-lg bg-white/80 backdrop-blur-sm">
            <CardContent className="p-8 md:p-12">
              <div className="flex items-center justify-center mb-8">
                <div className="w-16 h-16 bg-purple-100 rounded-2xl flex items-center justify-center">
                  <History className="w-8 h-8 text-[#9b87f5]" />
                </div>
              </div>
              <h2 className="text-3xl font-semibold text-center mb-6 text-gray-800">
                Our Beginning
              </h2>
              <p className="text-gray-600 text-center max-w-4xl mx-auto text-lg leading-relaxed">
                In 2020, we built Workfolio—an employee productivity monitoring
                software—out of necessity. Initially a solution for our own
                team, it evolved into a valuable tool for companies worldwide
                struggling to manage team productivity and workforce
                organization.
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Challenge Section */}
        <div className="mb-20">
          <Card className="hover:shadow-xl transition-all duration-300 border-none shadow-lg overflow-hidden">
            <CardContent className="p-8 md:p-12 bg-gradient-to-br from-[#9b87f5]/5 to-transparent">
              <div className="flex items-center justify-center mb-8">
                <div className="w-16 h-16 bg-purple-100 rounded-2xl flex items-center justify-center">
                  <Target className="w-8 h-8 text-[#9b87f5]" />
                </div>
              </div>
              <h2 className="text-3xl font-semibold text-center mb-6 text-gray-800">{`A Remote Team's Challenge`}</h2>
              <p className="text-gray-600 text-lg leading-relaxed max-w-4xl mx-auto text-left">
                {`As a remote team working from villages and cities across South India, we faced unique challenges with daily standup meetings. 
                Through our experience with Workfolio's customers, we discovered many teams shared similar struggles with managing routine tasks, 
                tracking roadblocks, and gathering feedback. This realization led to an opportunity for innovation.`}
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Key Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-20">
          {/* Remote First */}
          <Card className="group hover:shadow-xl transition-all duration-300 border-none">
            <CardContent className="p-8 text-center space-y-6 h-full">
              <div className="w-16 h-16 bg-[#D3E4FD] rounded-2xl flex items-center justify-center mx-auto group-hover:scale-110 transition-transform duration-300">
                <Globe className="w-8 h-8 text-[#9b87f5]" />
              </div>
              <h3 className="text-2xl font-semibold text-gray-800">
                Remote First
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed ">
                As a remote team working from villages and cities across South
                India, we understand the unique challenges of remote
                collaboration.
              </p>
            </CardContent>
          </Card>

          {/* Our Team */}
          <Card className="group hover:shadow-xl transition-all duration-300 border-none">
            <CardContent className="p-8 text-center space-y-6 h-full">
              <div className="w-16 h-16 bg-[#D3E4FD] rounded-2xl flex items-center justify-center mx-auto group-hover:scale-110 transition-transform duration-300">
                <Users className="w-8 h-8 text-[#9b87f5]" />
              </div>
              <h3 className="text-2xl font-semibold text-gray-800">Our Team</h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                {`We're a passionate team dedicated to creating tools that make work more efficient 
                and meaningful for teams worldwide.`}
              </p>
            </CardContent>
          </Card>

          {/* Our Location */}
          <Card className="group hover:shadow-xl transition-all duration-300 border-none">
            <CardContent className="p-8 text-center space-y-6 h-full">
              <div className="w-16 h-16 bg-[#D3E4FD] rounded-2xl flex items-center justify-center mx-auto group-hover:scale-110 transition-transform duration-300">
                <MapPin className="w-8 h-8 text-[#9b87f5]" />
              </div>
              <h3 className="text-2xl font-semibold text-gray-800">
                Our Location
              </h3>
              <p className="text-gray-600 text-lg leading-relaxed">
                Based in South India, we bring a unique perspective to global
                workplace solutions.
              </p>
            </CardContent>
          </Card>
        </div>

        {/* Birth of Workfolio Bot */}
        <Card className="bg-gradient-to-r from-[#9b87f5] to-[#7E69AB] border-none shadow-xl">
          <CardContent className="p-8 md:p-12 text-center">
            <div className="flex items-center justify-center mb-8">
              <div className="w-16 h-16 bg-white/20 backdrop-blur-sm rounded-2xl flex items-center justify-center">
                <Sparkles className="w-8 h-8 text-white" />
              </div>
            </div>
            <h2 className="text-3xl font-semibold mb-6 text-white">
              The Birth of Workfolio Bot
            </h2>
            <p className="max-w-4xl mx-auto text-white/90 text-lg leading-relaxed">
              {`Born from our own team's challenges with daily standups and routine tasks, 
              Workfolio Bot represents our commitment to optimizing and automating meetings, 
              streamlining reminders, and freeing up time for meaningful work. While we're not 
              building something as groundbreaking as Tesla or SpaceX, we're proud to be creating 
              a tool that saves time and energy, allowing people to focus on what truly matters.`}
            </p>
          </CardContent>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
