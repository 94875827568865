import { serverUrl } from "./serverUrl";
import { Trigger } from "@/pages/types";

export const deleteTrigger = async (trigger_id: string) => {
  try {
    // constructing the API endpoint with the trigger ID
    const response = await fetch(`${serverUrl}/api/triggers/${trigger_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log("Response:", response);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    const data = await response.json();
    console.log("Trigger deleted successfully");
  } catch (error: any) {
    console.error("Error in openDeleteForm:", error.message);
    alert("Failed to delete trigger. Please try again later.");
  }
};
