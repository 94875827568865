import { Badge } from "@/components/ui/badge";
import { HelpCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";

interface CommandCardProps {
  command: string;
  onClick: () => void;
}

export const getEventBadgeColor = (eventType: string) => {
  switch (eventType) {
    case "work_start":
      return "bg-green-500";
    case "work_end":
      return "bg-red-500";
    case "break_start":
      return "bg-yellow-500";
    case "break_end":
      return "bg-blue-500";
    default:
      return "bg-gray-500";
  }
};

export const getEventLabel = (eventType: string) => {
  switch (eventType) {
    case "work_start":
      return "Started Work";
    case "work_end":
      return "Ended Work";
    case "break_start":
      return "Started Break";
    case "break_end":
      return "Resumed Work";
    default:
      return eventType;
  }
};

const getCommandHelp = (command: string) => {
  const helpData = {
    work_start: {
      command: "/startwork",
      purpose: "Log the beginning of your work session.",
      example: "/startwork",
      what: "Your work session starts and is logged on the Workfolio dashboard."
    },
    work_end: {
      command: "/stopwork",
      purpose: "End your current work session.",
      example: "/stopwork",
      what: "Your work session ends and is logged on the dashboard."
    },
    break_start: {
      command: "/takebreak",
      purpose: "Log when you're stepping away for a break.",
      example: "/takebreak",
      what: "Your status changes to \"On Break,\" and the activity is recorded."
    },
    break_end: {
      command: "/resumework",
      purpose: "Resume work after a break or pause.",
      example: "/resumework",
      what: "Your status switches back to \"Working,\" and the session resumes on the dashboard."
    }
  };

  return helpData[command as keyof typeof helpData];
};

export const CommandCard = ({ command, onClick }: CommandCardProps) => {
  const [showHelp, setShowHelp] = useState(false);
  const helpData = getCommandHelp(command);

  return (
    <div className="relative">
      <div
        className="p-4 border rounded-lg cursor-pointer hover:border-primary transition-colors relative group"
        onClick={onClick}
      >
        <Dialog open={showHelp} onOpenChange={setShowHelp}>
          <DialogTrigger asChild>
            <button
              className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                setShowHelp(true);
              }}
            >
              <HelpCircle className="h-5 w-5 text-muted-foreground" />
            </button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>How to use {helpData.command}</DialogTitle>
              <DialogDescription className="space-y-4 pt-4">
                <div>
                  <p className="font-semibold">Purpose:</p>
                  <p>{helpData.purpose}</p>
                </div>
                <div>
                  <p className="font-semibold">Example:</p>
                  <code className="bg-muted px-2 py-1 rounded">{helpData.example}</code>
                </div>
                <div>
                  <p className="font-semibold">What Happens:</p>
                  <p>{helpData.what}</p>
                </div>
                <div className="pt-4 text-sm">
                  <p className="font-semibold">Where to Use:</p>
                  <p>Use these commands in team channels or direct message to the Workfolio Bot for personal logging.</p>
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
        <Badge className={getEventBadgeColor(command)}>
          /work {command.split("_")[1]}
        </Badge>
        <p className="text-sm text-muted-foreground mt-2">
          {getEventLabel(command)}
        </p>
      </div>
    </div>
  );
};