import { Quote } from "lucide-react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

export const Testimonial = () => {
  const testimonials = [
    {
      quote:
        "Workfolio saved our team hours every week. Our standups are now on autopilot!",
      author: "Arun",
      role: "Product Manager",
      company: "DesignQube",
      avatar: "/logo-v1.png",
    },
    {
      quote:
        "The automated surveys have given us incredible insights into team productivity and satisfaction.",
      author: "Sarah",
      role: "Team Lead",
      company: "TechFlow Solutions",
      avatar: "/logo-v1.png",
    },
    {
      quote:
        "Best investment we've made for our remote team. Communication has never been smoother.",
      author: "Michael",
      role: "CEO",
      company: "CloudScale Inc",
      avatar: "/logo-v1.png",
    },
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-accent to-white">
      <div className="container max-w-6xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Loved by Teams Worldwide</h2>
          <p className="text-xl text-gray-600">
            See what our customers have to say about Workfolio Bot
          </p>
        </div>

        <Carousel className="w-full">
          <CarouselContent>
            {testimonials.map((testimonial, index) => (
              <CarouselItem key={index}>
                <div className="glass-card p-8 md:p-12 rounded-2xl text-center scroll-reveal hover:shadow-xl transition-shadow duration-300">
                  <div className="relative mb-8">
                    <Quote className="w-12 h-12 mx-auto text-[#6b46c1] opacity-20" />
                  </div>

                  <p className="text-xl md:text-2xl font-medium mb-8 text-gray-800 leading-relaxed">
                    {`"${testimonial.quote}"`}
                  </p>

                  <div className="flex flex-col items-center">
                    <img
                      src={testimonial.avatar}
                      alt={testimonial.author}
                      className="w-16 h-16 rounded-full mb-4 border-2 border-[#6b46c1]/20"
                    />
                    <div className="space-y-1">
                      <h4 className="font-semibold text-lg text-[#6b46c1]">
                        {testimonial.author}
                      </h4>
                      <p className="text-gray-600">{testimonial.role}</p>
                      <p className="text-gray-500 text-sm">
                        {testimonial.company}
                      </p>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="hidden md:block">
            <CarouselPrevious className="-left-16 bg-white/80 hover:bg-white" />
            <CarouselNext className="-right-16 bg-white/80 hover:bg-white" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};
