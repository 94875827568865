import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { TriggerForm } from "../components/trigger-fields/TriggerForm";
import { useState } from "react";

interface StandupFieldsType {
  questions: string[];
  time?: string;
  days?: string[];
  frequency?: string;
  recipients?: string[];
  responseChannel?: string[];
}

export default function CreateStandup() {
  const navigate = useNavigate();

  const availableUsers = [
    { label: "User 1", value: "user1" },
    { label: "User 2", value: "user2" },
  ];

  const availableChannels = [
    { label: "#general", value: "general" },
    { label: "#standup", value: "standup" },
  ];

  const [fields, setFields] = useState<StandupFieldsType>({
    questions: [],
    time: "",
    days: [],
    frequency: "",
    recipients: [],
    responseChannel: [],
  });

  const handleFieldsChange = (newFields: StandupFieldsType) => {
    setFields({
      questions: newFields.questions || [],
      time: newFields.time || "",
      days: newFields.days || [],
      frequency: newFields.frequency || "",
      recipients: newFields.recipients || [],
      responseChannel: newFields.responseChannel || [],
    });
  };

  return (
    <div className="container mx-auto p-8">
      <Button variant="ghost" asChild className="mb-8">
        <Link to="/standups" className="flex items-center gap-2">
          <ArrowLeft className="h-4 w-4" />
          Back to Standups
        </Link>
      </Button>

      <Dialog open={true} onOpenChange={() => navigate("/standups")}>
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Create New Standup</DialogTitle>
          </DialogHeader>
          <TriggerForm
            fields={fields}
            onFieldsChange={handleFieldsChange}
            availableUsers={availableUsers}
            availableChannels={availableChannels}
          />
          <div className="flex justify-end gap-2 mt-4">
            <Button variant="outline" onClick={() => navigate("/standups")}>
              Cancel
            </Button>
            <Button>Create Standup</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
