import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const Hero = () => {
  const navigate = useNavigate();
  return (
    <section className="min-h-[80vh] flex items-center justify-center px-4 py-20">
      <div className="max-w-4xl mx-auto text-center animate-fade-up">
        <div className="mb-4 flex flex-col items-center">
          <img
            src="/logo-v2.png"
            alt="Workfolio Bot Mascot"
            className="w-32 h-32 mb-4 animate-bounce"
          />
          <span className="text-2xl font-bold text-[#6b46c1]">
            Workfolio Bot
          </span>
        </div>
        <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
          Automate Standups, Surveys, and Reminders Effortlessly
        </h1>
        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
          Tired of missed updates, forgotten check-ins, and disconnected teams?{" "}
          <span className="font-semibold text-[#6b46c1]">Workfolio Bot</span>{" "}
          {`automates your team's routine, ensuring nothing falls through the
          cracks while keeping everyone aligned and engaged.`}
        </p>
        <div className="flex gap-4 justify-center">
          <Button
            size="lg"
            className="hover-lift hover:bg-[#6b46c1]/90 bg-[#6b46c1] p-6"
            onClick={() => navigate("/login")}
          >
            Try Workfolio Bot for Free
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
          <Button size="lg" variant="outline" className="hover-lift p-6">
            Watch Demo
          </Button>
        </div>
      </div>
    </section>
  );
};
