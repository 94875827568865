import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { useState } from "react";
import { DateRangePicker } from "@/components/ui/date-range-picker";
import { addDays } from "date-fns";
import { DateRange } from "react-day-picker";
import { CommandCard } from "@/components/slack-commands/CommandCard";
import { EventsTable } from "@/components/slack-commands/EventsTable";
import { workEvents } from "@/components/slack-commands/types";

export default function SlackCommands() {
  const [selectedCommand, setSelectedCommand] = useState<string | null>(null);
  const [date, setDate] = useState<DateRange | undefined>({
    from: addDays(new Date(), -7),
    to: new Date(),
  });

  const filteredEvents = workEvents.filter((event) => {
    if (!date?.from || !date?.to) return true;
    return event.timestamp >= date.from && event.timestamp <= date.to;
  });

  const commandEvents = workEvents.filter(
    (event) => event.eventType === selectedCommand
  );

  return (
    <div className="p-4 md:p-8">
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl font-bold tracking-tight">
          Slack Commands
        </h1>
        <p className="text-muted-foreground mt-2">
          {`View team members' work events logged through Slack commands`}
        </p>
      </div>

      <div className="grid gap-6">
        <Card className="p-4 md:p-6">
          <h2 className="text-lg md:text-xl font-semibold mb-4">
            Available Commands
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {["work_start", "break_start", "break_end", "work_end"].map(
              (command) => (
                <CommandCard
                  key={command}
                  command={command}
                  onClick={() => setSelectedCommand(command)}
                />
              )
            )}
          </div>
        </Card>

        <Card className="p-4 md:p-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-4">
            <h2 className="text-lg md:text-xl font-semibold">
              Recent Work Events
            </h2>
            <DateRangePicker date={date} setDate={setDate} />
          </div>
          <div className="overflow-x-auto">
            <EventsTable events={filteredEvents} />
          </div>
        </Card>
      </div>

      <Dialog
        open={!!selectedCommand}
        onOpenChange={() => setSelectedCommand(null)}
      >
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>
              {selectedCommand &&
                `${selectedCommand
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")} Events`}
            </DialogTitle>
            <DialogDescription>
              View all events for this command type
            </DialogDescription>
          </DialogHeader>
          <div className="overflow-x-auto">
            <EventsTable events={commandEvents} showEventType={false} />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
