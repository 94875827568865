import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export const PricingFAQ = () => {
  const faqs = [
    {
      question: "What is a trigger?",
      answer:
        "A trigger is an automated action that executes when certain conditions are met in your workflow.",
    },
    {
      question: "How does the Usage Add-on work?",
      answer:
        "Once you exceed your plan's trigger limit, each additional trigger will cost $3/month. This is automatically added to your monthly bill.",
    },
    {
      question: "Can I upgrade or downgrade my plan anytime?",
      answer:
        "Yes, you can change your plan at any time. When upgrading, you'll be charged the prorated amount for the remainder of the billing cycle.",
    },
    {
      question: "Is there a limit to the number of users?",
      answer:
        "No, all our plans support unlimited users. You only pay for the number of triggers you create.",
    },
  ];

  return (
    <section className="py-20 bg-accent">
      <div className="max-w-3xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-lg text-gray-600">
            Find answers to common questions about our pricing and features
          </p>
        </div>

        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger className="text-left">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="text-gray-600 text-left">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};
