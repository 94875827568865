import { Trigger, Trigger_question } from "../types";
import { serverUrl } from "../../components/serverUrl";

// function to delete a trigger
// passing the trigger object
export const deleteTrigger = async (trigger: Trigger | null) => {
  try {
    // constructing the API endpoint with the trigger ID
    const response = await fetch(
      `${serverUrl}/api/triggers/${trigger?.trigger_id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("Response:", response);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    const data = await response.json();
    console.log("Trigger deleted successfully");
  } catch (error: any) {
    console.error("Error in openDeleteForm:", error.message);
    alert("Failed to delete trigger. Please try again later.");
  }
};

// function to fetch all triggers
export async function fetchTriggers() {
  let data: Trigger[] = [];
  try {
    // constructing the API endpoint
    const response = await fetch(`${serverUrl}/api/triggers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    // console.log("Triggers fetched:", await response.text());
    data = await response.json();
    console.log("Triggers fetched :", data);
  } catch (error: any) {
    console.error("Error in getYourTriggers:", error.message);
    alert("Failed to fetch triggers. Please try again later.");
  }
  if (data) return data;
}

// function to fetch the questions for a trigger
export async function getYourTriggerQuestion(selectedTrigger: Trigger) {
  let triggerQuestion: string[] = [];
  console.log("triggerID-check", selectedTrigger.trigger_id);
  try {
    // constructing the API endpoint with the trigger ID
    const response = await fetch(
      `${serverUrl}/api/triggers/${selectedTrigger.trigger_id}/questions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    const data: Trigger_question[] = await response.json();
    console.log("Questions fetched:", data);
    // mapping the question_text to the triggerQuestion array
    triggerQuestion = data.map((q: any) => q.question_text) || [];
  } catch (error: any) {
    console.error("Failed to fetch trigger questions:", error.message);
  }
  console.log("triggerQuestion", triggerQuestion);
  if (triggerQuestion) return triggerQuestion;
}
