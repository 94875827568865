import {
  Bell,
  Sun,
  Handshake,
  HelpCircle,
  ChartBar,
  Smile,
  Heart,
  Edit,
} from "lucide-react";

const features = [
  {
    icon: Bell,
    title: "Send Reminders",
    emoji: "💡",
    description:
      "Keep everyone on track with personalized reminders for deadlines, meetings, and tasks.",
  },
  {
    icon: Sun,
    title: "Daily Stand-ups",
    emoji: "🌞",
    description:
      "Streamline your daily stand-ups with structured updates and blockers tracking.",
  },
  {
    icon: Handshake,
    title: "Monthly 1-on-1",
    emoji: "🤝",
    description:
      "Foster meaningful one-on-one conversations with your team members in a secure environment.",
  },
  {
    icon: HelpCircle,
    title: "Retrospective",
    emoji: "🤔",
    description:
      "Assess your sprint's progress and identify areas of improvement to ensure deliveries remain on track.",
  },
  {
    icon: ChartBar,
    title: "Sprint Health Check",
    emoji: "💻",
    description:
      "Monitor project progress, risks, and blockers with daily updates to stay ahead of challenges.",
  },
  {
    icon: Smile,
    title: "Icebreaker",
    emoji: "😊",
    description:
      "Kickstart your week with engaging icebreaker questions that spark creativity and collaboration.",
  },
  {
    icon: Handshake,
    title: "1-1 Meeting",
    emoji: "🤝",
    description:
      "Make periodic one-on-one meetings impactful with structured questions for open communication.",
  },
  {
    icon: Heart,
    title: "Well-being Check-in",
    emoji: "🖐️",
    description:
      "Build a supportive work culture by making mental health a priority with regular team updates.",
  },
  {
    icon: Edit,
    title: "Start from Scratch",
    emoji: "⚡",
    description:
      "Create your own questions for a tailored check-in experience perfect for unique team workflows.",
  },
];

export const Features = () => {
  return (
    <section className="py-20 px-4 bg-gradient-to-b from-white to-accent/30">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">Available Templates</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Choose from our pre-built templates or create your own to keep your
            team synchronized
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-sm border border-gray-100/50 
                        transition-all duration-300 hover:shadow-lg hover:-translate-y-1 hover:border-[#6b46c1]/20
                        hover:bg-white/90"
            >
              <div className="flex items-start gap-4">
                <div
                  className="bg-[#6b46c1]/5 rounded-lg p-2 w-12 h-12 flex items-center justify-center shrink-0
                              group-hover:bg-[#6b46c1]/10 transition-colors duration-300"
                >
                  <feature.icon className="h-6 w-6 text-[#6b46c1] group-hover:scale-110 transition-transform duration-300" />
                </div>
                <div className="flex-1">
                  <div className="flex items-center gap-2 mb-2">
                    <h3 className="text-xl font-semibold group-hover:text-[#6b46c1] transition-colors duration-300">
                      {feature.title}
                    </h3>
                    <span className="text-xl group-hover:scale-110 transition-transform duration-300">
                      {feature.emoji}
                    </span>
                  </div>
                  <p className="text-gray-600 text-sm leading-relaxed text-start">
                    {feature.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
