import { Footer } from "./components/Footer";

const Blog = () => {
  return (
    <div className="min-h-screen bg-white">
      <main className="container mx-auto px-4 py-12 mx-auto max-w-[70%] my-10">
        <article className="prose lg:prose-xl mx-auto">
          <h1 className="text-[56px] font-bold mb-8 text-center leading-none">
            The Story Behind Workfolio Bot
          </h1>

          <div className="space-y-6">
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`In 2020, we built Workfolio—an employee productivity monitoring software—out of necessity. Initially, it was a solution for our own team, but it quickly evolved into a valuable tool for companies worldwide struggling to manage team productivity and workforce organization. Workfolio has since become a trusted ally for businesses seeking to keep their teams on track and efficient.`}
            </p>

            <h2 className="text-[36px] font-semibold mt-8 mb-4 text-left ">
              {`A Remote Team's Challenge`}
            </h2>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`As a remote team working from villages and cities across South
              India, we faced unique challenges. One of our daily rituals was
              conducting standup meetings every morning. While these meetings
              had their merits, they often consumed time that could be better
              spent on meaningful tasks.`}
            </p>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`As we engaged with Workfolio's customers, we discovered that many
              teams faced similar issues. Managing routine tasks like daily
              standups, tracking roadblocks, sending reminders, and gathering
              feedback was a cumbersome process for many business owners,
              particularly in small and medium-sized businesses.`}
            </p>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`This seemingly small problem was draining time and energy for
              countless teams, leaving business owners overwhelmed. We realized
              this was an opportunity to innovate and create something
              impactful.`}
            </p>

            <h2 className="text-[36px] font-semibold mt-8 mb-4 text-left">
              The Birth of Workfolio Bot
            </h2>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`To address this problem, we started brainstorming. One day, during
              a discussion about the inefficiencies in our own processes, the
              idea for the Workfolio Bot was born. That day marked Day 0 for the
              Workfolio Bot.`}
            </p>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`We iterated on the concept, consulted with our customers, and
              built an initial version of the bot. The goal was simple yet
              profound: optimize and automate standup meetings, streamline
              reminders, and free up time for meaningful work.`}
            </p>

            <h2 className="text-[36px] font-semibold mt-8 mb-4 text-left">
              The Story Behind the Mascot
            </h2>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`When it came time to design the logo for Workfolio Bot, we decided
              to steer clear of a serious or overly corporate aesthetic.
              Instead, we wanted a logo that would evoke positivity, freshness,
              and joy—something that could bring a smile to anyone who saw it.
              After experimenting with AI tools, we created a butterfly mascot.
              The butterfly symbolizes transformation, lightness, and freedom,
              perfectly aligning with our vision for the bot.`}
            </p>

            <h2 className="text-[36px] font-semibold mt-8 mb-4 text-left">
              The Launch and Beyond
            </h2>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {` Now, as we prepare to launch the Workfolio Bot in beta, we're
              filled with excitement and anticipation. We know this is just the
              beginning. The bot is taking baby steps, and we're committed to
              learning and growing alongside our customers. Their feedback will
              shape the bot's evolution, making it more effective and valuable
              over time.`}
            </p>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {` While we're not building something as groundbreaking as Tesla or
              SpaceX, we're proud to be creating a tool that saves time and
              energy, allowing people to focus on meaningful work. Workfolio Bot
              is designed to hold our customers' hands, helping them overcome
              daily challenges and grow with them over time.`}
            </p>

            <h2 className="text-[36px] font-semibold mt-8 mb-4 text-left">
              A Heartfelt Thank You
            </h2>
            <p className="text-lg leading-relaxed text-justify text-gray-700">
              {`Our team is immensely grateful for the opportunity to serve users
              around the world. Every beginning is exciting, and this journey is
              no exception. Together, we'll make Workfolio Bot a trusted
              companion for businesses, one step at a time.`}
            </p>
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default Blog;
