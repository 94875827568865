import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Link } from "react-router-dom";

interface EmptyTemplateProps {
  name: string;
  link: string;
}

export default function EmptyTemplate({ name, link }: EmptyTemplateProps) {
  return (
    <Card className="bg-muted/50">
      <CardContent className="flex flex-col items-center justify-center py-16 text-center">
        <h2 className="text-xl font-semibold mb-2">{`No ${name} yet`}</h2>
        <p className="text-muted-foreground mb-4">
          Create your first team {name} to start tracking team wellness.
        </p>
        <Button asChild>
          <Link to={link}>Create {name}</Link>
        </Button>
      </CardContent>
    </Card>
  );
}
