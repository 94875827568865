import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { BarChart } from "recharts";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import  {fetchTriggerResponse, fetchTriggerQuestions}  from "./api";
import { fetchParticipants } from "../createTriggerModals/api";

interface TriggerAnalyticsProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  triggerTitle: string;
  triggerId: string;
}

const last7DaysData = [
  { day: "Mon", responses: 8 },
  { day: "Tue", responses: 7 },
  { day: "Wed", responses: 8 },
  { day: "Thu", responses: 8 },
  { day: "Fri", responses: 6 },
  { day: "Sat", responses: 4 },
  { day: "Sun", responses: 7 },
];


export function TriggerAnalytics({
  open,
  onOpenChange,
  triggerTitle,
  triggerId,
}: TriggerAnalyticsProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date()
  );
  const [responseData, setResponseData] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [sampleData, setSampleData] = useState<any[]>([]);

  // Fetch response data when triggerId changes
  useEffect(() => {
    async function fetchData() {
      // Clear the previous response data
      setResponseData([]);
      try {
        // fetch responses given by the participants of the trigger
        const responseData = await fetchTriggerResponse(triggerId, selectedDate);
        setResponseData(responseData);
        console.log("Fetched response data:", responseData);
      } catch (error) {
        console.error("Error fetching response data:", error);
      }
    }

    fetchData();
  }, [triggerId, selectedDate]);

  // Fetch participants when the component mounts
  useEffect(() => {

    async function fetchUsers() {
      try {
        // fetch participants of the trigger
        const participants = await fetchParticipants();
        setUsers(participants);
        console.log("Fetched participants:", participants);
      } catch (error) {
        console.error("Error fetching participants:", error);
      }
    }

    // if(open)
    fetchUsers();
  }, []);

  useEffect(() => {
    // Process response data to generate sampleData
    async function processResponses() {
      if (!responseData.length || !users.length) return;

      // Process response data to generate sampleData
      const processedData = await Promise.all(
        // Iterate over each response data
        responseData.map(async (data: any) => {
          // Check if response data is an object
          if (data && typeof data.response_data === "object") {
            // Iterate over each key-value pair in the response data object 
            const responses = await Promise.all(
              Object.entries(data.response_data).map(async ([key, value]) => {
                try {
                  // Fetch the question associated with the key (key is the question_id)
                  // and return an object with the question text and answer
                  const question = await fetchTriggerQuestions(triggerId, key);
                  return { question: question.question_text, answer: value };
                } catch (error) {
                  console.error("Error fetching questions:", error);
                  return null;
                }
              })
            );

            // Get the name of the participant
            const participantName = getUserName(data.participant_id);
            // Return an object with the trigger_id, user_id, name responses and time
            return {
              trigger_id: triggerId,
              user_id: data.participant_id,
              name: participantName,
              responses: responses.filter((r) => r), // Remove null responses
            };
          }
          console.warn("Invalid response data:", data);
          return null;
        })
      );
      // set the sampleData state to the filtered processedData
      setSampleData(processedData.filter((d) => d)); // Filter out null processed data
    }

    processResponses();
  }, [responseData, users, selectedDate]);

  // Get the name of the user based on the participantId
  const getUserName = (participantId: string) => {
    const user = users.find((user) => user.id === participantId);
    return user?.profile?.real_name || "Unknown User";
  };

  console.log("Sample Data:", sampleData);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-[#1A1F2C]">
            Analytics for {triggerTitle}
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          <div className="flex items-center gap-4">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-[240px] justify-start text-left font-normal",
                    !selectedDate && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {selectedDate ? format(selectedDate, "PPP") : "Select date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={selectedDate}
                  onSelect={setSelectedDate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-3">{`Today's Responses`}</h3>
            <p className="text-muted-foreground mb-2">
              {sampleData.length} people submitted their response today
            </p>
           {/* <Accordion type="single" collapsible className="w-full">
              {sampleData.map((responder, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger className="hover:no-underline">
                    <div className="flex justify-between w-full pr-4">
                      <span>{responder.name}</span>
                      <span className="text-sm text-muted-foreground">
                         {responder.time} 
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <p className="text-sm text-muted-foreground ">
                      {responder.responses.map((response: any, index: number) => (
                        <div key={index} className="flex flex-col items-left">
                          <div className="text-sm text-muted-foreground">
                            {index + 1}.{" "}{response.question}
                          </div>
                          <div className="text-sm text-muted-foreground ms-4">
                           {response.answer}
                          </div>
                        </div>
                      ))}
                    </p>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>*/}

            <Accordion type="single" collapsible className="w-full border rounded-md bg-gray-50">
  {sampleData.map((responder, index) => (
    <AccordionItem
      key={index}
      value={`item-${index}`}
      className="border-b last:border-none"
    >
      <AccordionTrigger className="hover:no-underline p-4">
        <div className="flex justify-between items-center w-full">
          <span className="text-lg font-semibold text-gray-800">
            {responder.name}
          </span>
          <span className="text-sm text-gray-500">
            {/* Placeholder for time if needed */}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className="bg-white p-4">
        <div className="space-y-4">
          {responder.responses.map((response: any, index: number) => (
            <div
              key={index}
              className="flex flex-col gap-1 border rounded-lg p-3 bg-gray-100"
            >
              <div className="font-medium text-gray-700">
                {index + 1}. {response.question}
              </div>
              <div className="text-gray-600 ml-4">
                <span className="font-semibold">Answer:</span> {response.answer}
              </div>
            </div>
          ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  ))}
</Accordion>

          </div>

          <div>
            <h3 className="text-lg font-semibold mb-3">Last 7 Days Activity</h3>
            <div className="w-full h-[250px] bg-white p-4 rounded-lg border">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={last7DaysData}
                  margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis
                    dataKey="day"
                    axisLine={false}
                    tickLine={false}
                    dy={10}
                  />
                  <YAxis axisLine={false} tickLine={false} dx={-10} />
                  <Tooltip
                    cursor={{ fill: "rgba(139, 92, 246, 0.1)" }}
                    contentStyle={{
                      background: "#fff",
                      border: "1px solid #e2e8f0",
                      borderRadius: "6px",
                      padding: "8px",
                    }}
                  />
                  <Bar
                    dataKey="responses"
                    fill="#8B5CF6"
                    radius={[4, 4, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
