import { serverUrl } from "../../components/serverUrl";


export async function fetchTriggerResponse(triggerId: string, date: Date | undefined) {
    let triggerResponse: any = [];
    console.log("fetchTriggerResponse", date);
    const dateAt12 = new Date(date || new Date());
    dateAt12.setHours(0);
    dateAt12.setMinutes(0);
    dateAt12.setSeconds(0);
    dateAt12.setMilliseconds(0);
    console.log("dateAt12", dateAt12);
    try {
        // constructing the API endpoint with the trigger ID
        const response = await fetch(
            `${serverUrl}/api/response/${triggerId}/${dateAt12}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data: any = await response.json();
        console.log("Trigger Response fetched:", data);
        // mapping the question_text to the triggerQuestion array
        triggerResponse = data || [];
    } catch (error: any) {
        console.error("Failed to fetch trigger response:", error.message);
    }
    console.log("triggerResponse", triggerResponse);
    if (triggerResponse) return triggerResponse;
}

export async function fetchTriggerQuestions(triggerId: string, questionId: string){
    let triggerQuestions: any = [];
    try {
        // constructing the API endpoint with the trigger ID
        const response = await fetch(
            `${serverUrl}/api/triggerQuestions/${triggerId}/${questionId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data: any = await response.json();
        console.log("Trigger Questions fetched:", data);
        // mapping the question_text to the triggerQuestion array
        triggerQuestions = data || [];
    } catch (error: any) {
        console.error("Failed to fetch trigger questions:", error.message);
        alert("Failed to fetch trigger questions. Please try again later.");
    }
    console.log("triggerQuestions", triggerQuestions);
    if (triggerQuestions) return triggerQuestions;
}