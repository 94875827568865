import { fetchTriggers } from "./api";

export const fetchData = async () => {
  try {
    const data = await fetchTriggers();
    
    console.log("Data fetched:", data);

    const formattedData = (data || []).map((trigger: any) => ({
      
      id: trigger.trigger_id ?? "",
      title: trigger.trigger_name ?? "Untitled",
      type: trigger.trigger_type ?? "unknown",
      time: trigger.trigger_time ?? "00:00",
      timeZone: trigger.trigger_timeZone ?? "UTC",
      frequency: trigger.trigger_frequency ?? "daily",
      participants: trigger.trigger_participants ?? [],
      days: trigger.trigger_days ?? [],
      recipients: trigger.trigger_recipients ?? [],
      content: trigger.content ?? "",
      questions: trigger.trigger_questions ?? [],
      status: trigger.trigger_status ?? "inactive",
      publish_status: trigger.publish_status ?? true,
    }));
    return formattedData;
  } catch (error) {
    console.error("Error fetching triggers:", error);
    return [];
  }
};
