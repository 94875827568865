import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { useState, useRef } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm, Controller } from "react-hook-form";
import { fetchData } from "@/pages/home/fetchData";
import createTriggerApi from "./api";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { TriggerTypeFields } from "@/components/TriggerTypeFields";
import { validateFields } from "./formValidation";

interface CreateTriggerModalProps {
  setYourTriggers: (data: any) => void;
}

const CreateTriggerModal: React.FC<CreateTriggerModalProps> = ({
  setYourTriggers,
}) => {
  const [selectedTriggerType, setSelectedTriggerType] = useState("");

  // State for the form fields
  const [fieldValues, setFieldValues] = useState({
    content: "",
    time: "",
    timeZone: "",
    frequency: "",
    days: [],
    responseChannel: [],
    participants: [],
    questions: [],
  });
  const [error, setError] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const triggerButtonRef = useRef<HTMLButtonElement>(null);

  async function onClose() {
    const data = await fetchData();
    console.log("data after create-close", data);
    setYourTriggers(data);
    setOpen(false);
    setFormOpen(false);
  }

  // Form state for handling form validation
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Handles field changes in the form
  const handleFieldsChange = (fields: any) => {
    setFieldValues(fields);
  };

  // Handles form submission
  const onSubmit = async (data: any) => {
    console.log("submit", data);
    // Check if trigger name is present
    if (!data.triggerName) {
      setError(["Trigger name is required"]);
      return;
    }

    // Validate form fields
    // validateFields is a function that takes trigger type and form field values as arguments
    // validateFields returns an array of errors if any
    const validationErrors = validateFields(selectedTriggerType, fieldValues);
    if (validationErrors.length === 0) {
      console.log("Form submitted successfully:", fieldValues);
      reset();
    } else {
      setError(validationErrors);
      return;
    }

    // Create trigger data object based on trigger type
    if (selectedTriggerType === "reminder") {
      data = {
        trigger_name: data.triggerName,
        trigger_type: "reminder",
        trigger_period: fieldValues.frequency,
        trigger_time: fieldValues.time,
        trigger_timeZone: fieldValues.timeZone,
        trigger_days: fieldValues.days,
        trigger_participants: fieldValues.participants,
        trigger_desc: fieldValues.content,
      };
    } else {
      // Create trigger data object for non-reminder triggers
      data = {
        trigger_name: data.triggerName,
        trigger_type: selectedTriggerType,
        trigger_time: fieldValues.time,
        trigger_timeZone: fieldValues.timeZone,
        trigger_period: fieldValues.frequency,
        trigger_days: fieldValues.days,
        trigger_responseChannel: fieldValues.responseChannel,
        trigger_participants: fieldValues.participants,
        trigger_ques: fieldValues.questions,
      };
    }

    // Call the createTriggerApi function to create the trigger
    // createTriggerApi is a function that takes trigger data as an argument
    // It returns a Promise that resolves to the response from the API
    try {
      const response = await createTriggerApi(data);
      console.log(response);
      // Check if the response status is 201 (Created)
      if (response.status === 201) {
        if (triggerButtonRef.current) {
          triggerButtonRef.current.click();
        }
        reset();
        setFieldValues({
          content: "",
          time: "",
          timeZone: "",
          frequency: "",
          days: [],
          responseChannel: [],
          participants: [],
          questions: [],
        });
      } else {
        alert("Error creating trigger");
      }
    } catch (error) {
      alert("Error submitting form:");
      console.error("Error submitting form:", error);
    }
  };

  return (
    // Dialog component for creating a new trigger
    <Dialog open={formOpen} onOpenChange={setFormOpen}>
      <DialogTrigger asChild>
        <Button
          className="gap-2 bg-purple-500 hover:bg-purple-600 text-white"
          size="lg"
        >
          <Plus className="h-4 w-4" />
          Create Trigger
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Create New Trigger</DialogTitle>
          <DialogDescription>
            Set up a new automated trigger for your team
          </DialogDescription>
        </DialogHeader>
        {/* Form component for creating a new trigger */}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          {/* Input component for trigger name */}
          <div className="space-y-2">
            <Label htmlFor="triggerName">Trigger Name</Label>
            <Controller
              name="triggerName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  id="triggerName"
                  placeholder="e.g., Daily Standup"
                  {...field}
                />
              )}
            />
          </div>
          {/* Select component for trigger type */}
          <div className="space-y-2">
            <Label htmlFor="selectedTriggerType">Type</Label>
            <Select
              value={selectedTriggerType}
              onValueChange={(value) => setSelectedTriggerType(value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select trigger type" />
              </SelectTrigger>
              <SelectContent className="h-[200px]">
                <SelectItem value="reminder">Reminder</SelectItem>
                <SelectItem value="standup">Standup</SelectItem>
                <SelectItem value="well-being">Well-being</SelectItem>
                <SelectItem value="monthly-1-on-1">Monthly 1-on-1</SelectItem>
                <SelectItem value="retrospective">Retrospective</SelectItem>
                <SelectItem value="sprint-health-check">
                  Sprint Health Check
                </SelectItem>
                <SelectItem value="icebreaker">Icebreaker</SelectItem>
                <SelectItem value="1-1-meeting">1-1 Meeting</SelectItem>
                <SelectItem value="start-from-scratch">
                  Start from Scratch
                </SelectItem>
              </SelectContent>
            </Select>
            {errors.selectedTriggerType && (
              <p className="text-red-500 text-sm">Trigger type is required</p>
            )}
          </div>

          {/* Renders the trigger type fields based on the selected trigger type */}
          {selectedTriggerType && (
            <>
              <TriggerTypeFields
                type={selectedTriggerType}
                onFieldsChange={handleFieldsChange}
                fieldValues={fieldValues}
              />
              {/* Error message  */}
              <div>
                {error.map((err, index) => (
                  <p key={index} className="text-red-500 text-[12px]">
                    {err}
                  </p>
                ))}
              </div>
            </>
          )}
          <Button type="submit" className="w-full">
            Create Trigger
          </Button>
          <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>
              <button ref={triggerButtonRef} style={{ display: "none" }} />
            </AlertDialogTrigger>

            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="text-xl">
                  Success!
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Your trigger has been successfully created
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <Button
                  className="m-2"
                  onClick={() => {
                    reset(); // Reset form fields
                    setFieldValues({
                      content: "",
                      time: "",
                      timeZone: "",
                      frequency: "",
                      days: [],
                      responseChannel: [],
                      participants: [],
                      questions: [],
                    }); // Clear fieldValues state
                    setSelectedTriggerType(""); // Clear selectedTriggerType state
                    setOpen(false); // Close the success modal
                    setFormOpen(false); // Close the trigger modal
                    setTimeout(() => setFormOpen(true), 0); // Reopen modal to refresh form state
                  }}
                >
                  Create Another Trigger
                </Button>

                <Button variant="secondary" className="m-2" onClick={onClose}>
                  Go to Home
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTriggerModal;
