import { serverUrl } from "../../components/serverUrl";
// Define the TriggerDetails interface for better type safety
export interface TriggerDetails {
  trigger_name: string;
  trigger_participants: string[];
  trigger_responseChannel: string[];
  trigger_desc?: string;
  trigger_period?: string;
  trigger_days?: string[];
  trigger_type: string;
  trigger_time: string;
  trigger_ques?: string[];
}
interface Trigger_question {
  workspace_id: string; 
  trigger_id: string; 
  question_id: string; 
  question_type: string; 
  question_text: string; 
  question_order?: number | null; 
  created_by: string; 
  created_at: number;
  trigger_status?: 'active' | 'deleted'; 
  latest_version?: 'yes' | 'no'; 
  last_modified_at?: number; 
}

// Function to create a trigger
async function createTriggerApi(triggerDetails: TriggerDetails): Promise<any> {
  console.log('Trigger details:', triggerDetails);
  try {
    // Construct the API endpoint with the trigger details
    const response = await fetch(`${serverUrl}/api/triggers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(triggerDetails),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('Data received:', data);
    return data;
  } catch (error: any) {
    console.error('Error in createTriggerApi:', error.message);
    throw error;
  }
}


const fetchChannels = async () => {
  try {
    const response = await fetch(`${serverUrl}/api/channels`);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching channels:", error);
  }
};

// Fetch participant list of the workspace from the server
const fetchParticipants = async () => {
  console.log("Fetching participants...");
  try {
    const response = await fetch(`${serverUrl}/api/participants`);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching participants:", error);
  }
};

// function to fetch the questions for a trigger
export async function getYourTriggerQuestion(triggerId: string) {
  let triggerQuestion: string[] = [];
  console.log("triggerID-check", triggerId);
  try {
    // constructing the API endpoint with the trigger ID
    const response = await fetch(
      `${serverUrl}/api/triggers/${triggerId}/questions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
    console.log("response fetched:", response);
    const data: Trigger_question[] = await response.json();
    console.log("Questions fetched:", data);
    // mapping the question_text to the triggerQuestion array
    triggerQuestion = data.map((q: any) => q.question_text) || [];
  } catch (error: any) {
    console.error("Failed to fetch trigger questions:", error.message);
  }
  console.log("triggerQuestion", triggerQuestion);
  if (triggerQuestion) return triggerQuestion;
  else return [];
}


export { fetchParticipants, fetchChannels };



export default createTriggerApi;
