import { Mail, Phone, MessageSquare, MapPin } from "lucide-react";

import { Button } from "@/components/ui/button";

import { Input } from "@/components/ui/input";

import { Label } from "@/components/ui/label";

import { Textarea } from "@/components/ui/textarea";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

import { useToast } from "@/hooks/use-toast";
import { Footer } from "./components/Footer";

const Contact = () => {
  const { toast } = useToast();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    toast({
      title: "Message sent!",

      description: "We'll get back to you as soon as possible.",
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-muted py-12 px-4">
      <div className="max-w-5xl mx-auto space-y-12 my-5">
        {/* Hero Section */}

        <div className="text-center space-y-4 animate-fade-in">
          <h1 className="text-4xl font-bold">Get in Touch</h1>

          <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
            {`Have questions about Workfolio Bot? We're here to help! Reach out to
            us through any of the channels below.`}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Contact Information */}

          <div className="space-y-6">
            <Card className="border-none shadow-lg text-left">
              <CardHeader>
                <CardTitle className="text-2xl font-bold">
                  Contact Information
                </CardTitle>
              </CardHeader>

              <CardContent className="space-y-6">
                <div className="flex items-center gap-4">
                  <Mail className="text-primary" />

                  <div>
                    <h3 className="font-medium">Email</h3>

                    <p className="text-muted-foreground">
                      team@getworkfolio.com
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <MessageSquare className="text-primary" />

                  <div>
                    <h3 className="font-medium">Live Chat</h3>

                    <a
                      href="https://tawk.to/workfolio"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary hover:underline"
                    >
                      Chat with us
                    </a>
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <MapPin className="text-primary" />

                  <div>
                    <h3 className="font-medium">Location</h3>

                    <p className="text-muted-foreground">South India</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Contact Form */}

          <div>
            <Card className="border-none shadow-lg text-left">
              <CardHeader>
                <CardTitle className="text-2xl font-bold">
                  Send us a Message
                </CardTitle>
              </CardHeader>

              <CardContent>
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="space-y-2">
                    <Label htmlFor="name">Name</Label>

                    <Input id="name" placeholder="Your name" required />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>

                    <Input
                      id="email"
                      type="email"
                      placeholder="Your email"
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <Label htmlFor="message">Message</Label>

                    <Textarea
                      id="message"
                      placeholder="How can we help you?"
                      className="min-h-[120px] w-full"
                      required
                    />
                  </div>

                  <Button type="submit" className="w-full">
                    Send Message
                  </Button>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
