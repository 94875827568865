import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface UsageStatsProps {
  currentPlan: "free" | "pro";
}

export function UsageStats({ currentPlan }: UsageStatsProps) {
  return (
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>Current Usage</CardTitle>
        <p className="text-sm text-muted-foreground mt-1">
          You are currently on the {currentPlan === "pro" ? "Pro" : "Free"} plan
        </p>
      </CardHeader>
      <CardContent>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Created Triggers</p>
            <p className="text-2xl font-bold">
              2 / {currentPlan === "pro" ? "Unlimited" : "2"}
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Remaining Triggers</p>
            <p className="text-2xl font-bold">
              {currentPlan === "pro" ? "Unlimited" : "0"}
            </p>
          </div>
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Trigger Hits This Month</p>
            <p className="text-2xl font-bold">
              456 / {currentPlan === "pro" ? "10,000" : "1,000"}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}