import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";

export const PricingMenu = () => {
  return (
    <section className="py-20 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-4">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-600">
            {`Choose the plan that's right for your team`}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {/* Free Plan */}
          <div className="glass-card p-8 rounded-sm hover-lift text-left">
            <h3 className="text-2xl font-bold mb-4">Free</h3>
            <div className="mb-6">
              <span className="text-4xl font-bold">$0</span>
              <span className="text-gray-600">/month</span>
            </div>
            <ul className="space-y-4 mb-8">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>2 free trigger creation</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>1,000 trigger hits per month</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Unlimited users</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Basic support</span>
              </li>
            </ul>
            <Button className="w-full bg-[#6b46c1] hover:bg-[#6b46c1]/90">
              Get Started
            </Button>
          </div>

          {/* Pro Plan */}
          <div className="glass-card p-8 rounded-sm hover-lift relative text-left">
            <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
              <span className="bg-[#6b46c1] text-white px-4 py-1 rounded-full text-sm ">
                Most Popular
              </span>
            </div>
            <h3 className="text-2xl font-bold mb-4">Pro</h3>
            <div className="mb-6">
              <span className="text-4xl font-bold">$29</span>
              <span className="text-gray-600">/month</span>
            </div>
            <ul className="space-y-4 mb-8">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>50 trigger creation</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Unlimited trigger hits</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Unlimited users</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Priority support</span>
              </li>
            </ul>
            <Button className="w-full bg-[#6b46c1] hover:bg-[#6b46c1]/90">
              Start 30 Days Free Trial
            </Button>
          </div>
        </div>

        {/* Add-on Section */}
        <div className="max-w-2xl mx-auto mt-16 text-center">
          <div className="glass-card p-8 rounded-sm">
            <h3 className="text-2xl font-bold mb-4">Usage Add-on</h3>
            <div className="mb-6">
              <span className="text-4xl font-bold">$3</span>
              <span className="text-gray-600">/trigger/month</span>
            </div>
            <p className="text-gray-600 mb-4">
              Need more triggers? Easily scale your workflow with our flexible
              add-on pricing.
            </p>
            <ul className="space-y-4 mb-8 text-left max-w-md mx-auto">
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Pay only for what you need</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Add or remove triggers anytime</span>
              </li>
              <li className="flex items-center">
                <Check className="h-5 w-5 text-[#6b46c1] mr-2" />
                <span>Automatic monthly billing</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
