import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ReminderFields } from "@/components/trigger-fields/ReminderFields";
import { useState } from "react";

interface ReminderFieldsType {
  content: string;
  time: string;
  timeZone: string;
  frequency: string;
  days: string[];
  participants?: string[];
}

export default function CreateReminder() {
  const navigate = useNavigate();
  const [fields, setFields] = useState<ReminderFieldsType>({
    content: "",
    time: "",
    timeZone: "",
    frequency: "",
    days: [],
    participants: [],
  });
  const availableUsers = [
    { label: "User 1", value: "user1" },
    { label: "User 2", value: "user2" },
  ];

  const availableChannels = [
    { label: "#general", value: "general" },
    { label: "#standup", value: "standup" },
  ];
  const handleFieldsChange = (newFields: ReminderFieldsType) => {
    setFields({
      content: newFields.content || "",
      time: newFields.time || "",
      timeZone: newFields.timeZone || "",
      frequency: newFields.frequency || "",
      days: newFields.days || [],
      participants: newFields.participants || [],
    });
  };

  return (
    <div className="container mx-auto p-8">
      <Button variant="ghost" asChild className="mb-8">
        <Link to="/reminders" className="flex items-center gap-2">
          <ArrowLeft className="h-4 w-4" />
          Back to Reminders
        </Link>
      </Button>

      <Dialog open={true} onOpenChange={() => navigate("/reminders")}>
        <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Create New Reminder</DialogTitle>
          </DialogHeader>
          <ReminderFields
            fields={fields}
            onFieldsChange={setFields}
            availableUsers={availableUsers}
          />
          <div className="flex justify-end gap-2 mt-4">
            <Button variant="outline" onClick={() => navigate("/reminders")}>
              Cancel
            </Button>
            <Button>Create Reminder</Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
