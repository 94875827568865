import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useState } from "react";
import { TriggerAnalytics } from "@/components/TriggerAnalytics/TriggerAnalytics";
import { MemberActivityChart } from "@/components/MemberActivityChart";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const participationData = [
  { name: "Week 1", standups: 85, meetings: 92 },
  { name: "Week 2", standups: 78, meetings: 88 },
  { name: "Week 3", standups: 90, meetings: 95 },
  { name: "Week 4", standups: 88, meetings: 90 },
];

const triggers = [
  { id: "1", name: "Daily Standup" },
  { id: "2", name: "Weekly Team Meeting" },
  { id: "3", name: "Project Updates" },
];

const Analytics = () => {
  const [selectedTrigger, setSelectedTrigger] = useState<string>("all");
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);

  return (
    <div className="p-8 space-y-8">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Team Analytics</h1>
          <p className="text-muted-foreground mt-1">
            Monitor team participation and engagement
          </p>
        </div>
        <Select
          value={selectedTrigger}
          onValueChange={setSelectedTrigger}
        >
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Select trigger" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Triggers</SelectItem>
            {triggers.map((trigger) => (
              <SelectItem key={trigger.id} value={trigger.id}>
                {trigger.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="grid gap-6">
        {selectedTrigger === "all" ? (
          <Card className="p-6">
            <h2 className="text-xl font-semibold mb-4">Overall Participation Rate</h2>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={participationData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="standups" fill="#8B5CF6" name="Standups" />
                  <Bar dataKey="meetings" fill="#6366F1" name="Meetings" />
                </BarChart>
              </ResponsiveContainer>
              <TriggerAnalytics 
                open={isAnalyticsOpen} 
                onOpenChange={setIsAnalyticsOpen} 
                triggerTitle="All Triggers"
                triggerId={selectedTrigger}
              />
            </div>
          </Card>
        ) : (
          <Card className="p-6">
            <h2 className="text-xl font-semibold mb-4">
              {triggers.find(t => t.id === selectedTrigger)?.name} Analytics
            </h2>
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={participationData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="standups" fill="#8B5CF6" name="Responses" />
                </BarChart>
              </ResponsiveContainer>
              <TriggerAnalytics 
                open={isAnalyticsOpen} 
                onOpenChange={setIsAnalyticsOpen} 
                triggerTitle={triggers.find(t => t.id === selectedTrigger)?.name || ""}
                triggerId={selectedTrigger}
              />
            </div>
          </Card>
        )}
        
        <MemberActivityChart />
      </div>
    </div>
  );
};

export default Analytics;