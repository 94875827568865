import { Quote } from "lucide-react";

export const ProPlanTestimonials = () => {
  const testimonials = [
    {
      quote:
        "The Pro plan's analytics features helped us optimize our team's productivity by 40%",
      author: "Sarah Chen",
      role: "Engineering Manager",
      company: "TechFlow Solutions",
      avatar: "/logo-v1.png",
    },
    {
      quote:
        "Timesheet management and export features save us hours every week in reporting",
      author: "Michael Rodriguez",
      role: "Project Lead",
      company: "CloudScale Inc",
      avatar: "/logo-v1.png",
    },
  ];

  return (
    <section className="py-12 bg-gray-50">
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-10">
          <h2 className="text-3xl font-bold mb-4">Why Teams Choose Pro</h2>
          <p className="text-lg text-gray-600">
            {`Join thousands of teams who've upgraded to unlock the full potential of their workflow`}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="glass-card p-6 rounded-lg hover:shadow-lg transition-all duration-300"
            >
              <div className="mb-4">
                <Quote className="h-8 w-8 text-[#6b46c1] opacity-50" />
              </div>
              <p className="text-lg mb-6 text-gray-800 text-left">{`"${testimonial.quote}"`}</p>
              <div className="flex items-center">
                <img
                  src={testimonial.avatar}
                  alt={testimonial.author}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div className="text-left">
                  <h4 className="font-semibold text-[#6b46c1]">
                    {testimonial.author}
                  </h4>
                  <p className="text-sm text-gray-600">{testimonial.role}</p>
                  <p className="text-sm text-gray-500">{testimonial.company}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
