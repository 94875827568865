import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Clock, Users, Edit2, BarChart2, Trash2 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { TriggerTypeFields } from "@/components/TriggerTypeFields";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { TriggerAnalytics } from "@/components/TriggerAnalytics/TriggerAnalytics";
import createTriggerApi from "./createTriggerModals/api";
import { useToast } from "@/hooks/use-toast";
import { useForm, Controller } from "react-hook-form";
import { validateFields } from "./createTriggerModals/formValidation";
import { useNavigate } from "react-router-dom";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { deleteTrigger } from "./deleteTriggerApi";
import { fetchTriggers } from "@/pages/home/api";
import { fetchData } from "@/pages/home/fetchData";

interface TriggerCardProps {
  id: string;
  title: string;
  type: string;
  time: string;
  timeZone: string;
  participants: string[];
  frequency: string;
  recipients: string[];
  days: string[];
  content: string;
  questions: string[];
  status?: string | "active" | "inactive";
  publish_status: boolean;
  setYourTriggers: any; // Pass setYourTriggers as a prop
}

export function TriggerCard({
  id,
  title,
  type,
  time,
  timeZone,
  frequency,
  participants,
  days,
  recipients,
  content,
  questions,
  status,
  publish_status,
  setYourTriggers,
}: TriggerCardProps) {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedType, setEditedType] = useState(type);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [isPublish_status, setIsPublish_status] = useState(publish_status);
  const [fieldValues, setFieldValues] = useState({
    id: id || "",
    content: content || "",
    time: time || "",
    timeZone: timeZone || "",
    frequency: frequency || "",
    days: days || [],
    responseChannel: recipients || [],
    participants: participants || [],
    questions: questions || [],
  });
  const [fieldValues1, setFieldValues1] = useState({
    id: id || "",
    content: content || "",
    time: time || "",
    timeZone: timeZone || "",
    frequency: frequency || "",
    days: days || [],
    responseChannel: recipients || [],
    participants: participants || [],
    questions: questions || [],
  });

  console.log("fieldValues1", fieldValues);
  const selectedTriggerType = type;
  const getTypeColor = (type: string) => {
    // assign colors based on trigger type
    switch (type) {
      case "reminder":
        return "bg-indigo-100 text-indigo-800 border-0"; // Light Indigo
      case "standup":
        return "bg-green-100 text-green-800 border-0"; // Soft Teal
      case "well-being":
        return "bg-violet-100 text-violet-800 border-0"; // violet
      case "monthly-1-on-1":
        return "bg-rose-100 text-rose-800 border-0"; // Rosy Pink
      case "retrospective":
        return "bg-fuchsia-100 text-fuchsia-800 border-0"; // Vibrant Fuchsia
      case "sprint-health-check":
        return "bg-stone-100 text-stone-800 border-0"; // Neutral Stone
      case "icebreaker":
        return "bg-amber-100 text-amber-800 border-0"; // Bright Amber
      case "1-1-meeting":
        return "bg-lime-100 text-lime-800 border-0"; // Fresh Lime
      case "start-from-scratch":
        return "bg-sky-100 text-sky-800 border-0"; // Calm Sky Blue
      default:
        return "bg-zinc-100 text-zinc-800 border-0"; // Soft Gray
    }
  };

  const handleFieldsChange = (fields: any) => {
    setFieldValues(fields);
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsEditing(true);
    setDialogOpen(true);
  };

  const handleAnalytics = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnalyticsOpen(true);
  };

  async function handleDelete() {
    // Delete the trigger from the API
    deleteTrigger(id);

    setDialogOpen(false);

    const data = await fetchData();
    console.log("data after delete", data);
    setYourTriggers(data);
  }

  const [error, setError] = useState<string[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    console.log("publich_status", isPublish_status);
    setIsEditing(false);
    setDialogOpen(false);

    if (!editedTitle) {
      setError(["Trigger name is required"]);
      console.error("error", error);
      return;
    }
    const validationErrors = validateFields(editedType, fieldValues);
    if (validationErrors.length === 0) {
      console.log("Form submitted successfully:", fieldValues);
      reset();
    } else {
      setError(validationErrors);
      return;
    }

    if (selectedTriggerType === "reminder") {
      data = {
        trigger_id: id,
        trigger_name: editedTitle,
        trigger_type: "reminder",
        trigger_period: fieldValues.frequency,
        trigger_time: fieldValues.time,
        trigger_timeZone: fieldValues.timeZone,
        trigger_days: fieldValues.days,
        trigger_participants: fieldValues.participants,
        trigger_desc: fieldValues.content,
        publish_status: isPublish_status,
      };
    } else {
      data = {
        trigger_id: id,
        trigger_name: editedTitle,
        trigger_type: editedType,
        trigger_time: fieldValues.time,
        trigger_timeZone: fieldValues.timeZone,
        trigger_days: fieldValues.days,
        trigger_responseChannel: fieldValues.responseChannel,
        trigger_participants: fieldValues.participants,
        publish_status: isPublish_status,
        trigger_ques : fieldValues.questions,
      };
    }

    console.log("data", data);

    try {
      const response = await createTriggerApi(data);
      console.log(response);
      if (response.status === 201) {
        alert("Trigger updated successfully!");
        reset();

        setFieldValues({
          id: "",
          content: "",
          time: "",
          timeZone: "",
          frequency: "",
          days: [],
          responseChannel: [],
          participants: [],
          questions: [],
        });
        const data = await fetchData();
        console.log("data after edit", data);
        setYourTriggers(data);
        navigate("/home");
      } else {
        alert("Error creating trigger");
      }
    } catch (error) {
      alert("Error submitting form:");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogTrigger asChild>
          <Card className="hover:bg-accent/50 transition-colors cursor-pointer bg-white border-[#D6BCFA] hover:border-[#9b87f5] group">
            <div className="p-4">
              <div className="flex justify-between items-start mb-3">
                <Badge variant="outline" className={getTypeColor(type)}>
                  {type}
                </Badge>
                <div className="flex gap-2">
                  <Badge
                    variant={isPublish_status ? "default" : "secondary"}
                    className={
                      isPublish_status
                        ? "bg-[#F2FCE2] text-green-800 hover:bg-[#F2FCE2] border-0"
                        : ""
                    }
                  >
                    {isPublish_status ? "Active" : "Inactive"}
                  </Badge>
                  {type !== "reminder" && (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-6 w-6 opacity-30 group-hover:opacity-100 transition-opacity"
                    onClick={handleAnalytics}
                  >
                    <BarChart2 className="h-4 w-4 text-[#8B5CF6]" />
                  </Button>
                  )}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-6 w-6 opacity-30 group-hover:opacity-100 transition-opacity"
                    onClick={handleEdit}
                  >
                    <Edit2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>
              <h3 className="text-lg font-semibold mb-3">{title}</h3>
              <div className="flex items-center gap-4 text-sm text-gray-600">
                <div className="flex items-center gap-2">
                  <Clock className="h-4 w-4" />
                  <span>{time}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Users className="h-4 w-4" />
                  <span>{participants.length} participants</span>
                </div>
              </div>
            </div>
          </Card>
        </DialogTrigger>

        <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
          {isEditing ? (
            <div className="space-y-4">
              <DialogHeader>
                <DialogTitle>Edit Trigger</DialogTitle>
              </DialogHeader>
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="title">Trigger Name</Label>
                    <Input
                      id="title"
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="type">Type</Label>
                    <Select
                      value={editedType}
                      onValueChange={(value: typeof editedType) =>
                        setEditedType(value)
                      }
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select trigger type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="reminder">Reminder</SelectItem>
                        <SelectItem value="standup">Standup</SelectItem>
                        <SelectItem value="well-being">Well-being</SelectItem>
                        <SelectItem value="retrospective">
                          Retrospective
                        </SelectItem>
                        <SelectItem value="monthly-1-on-1">
                          Monthly 1-on-1
                        </SelectItem>
                        <SelectItem value="sprint-health-check">
                          {" "}
                          Sprint Health Check
                        </SelectItem>
                        <SelectItem value="icebreaker">Icebreaker</SelectItem>
                        <SelectItem value="1-1-meetung">1:1 Meeting</SelectItem>
                        <SelectItem value="start-from-scratch">
                          Start from Scratch
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  {editedType && (
                    <>
                      <TriggerTypeFields
                        type={editedType}
                        onFieldsChange={handleFieldsChange}
                        fieldValues={fieldValues1}
                      />
                      <div className="space-y-2">
                        <div className="flex items-center justify-between gap-4">
                          <Label
                            htmlFor="active-switch"
                            className="text-sm font-medium cursor-pointer"
                          >
                            Active
                            <span className="text-muted-foreground text-[12px] px-2">
                              (You can pause a trigger if you no longer want it
                              to run.)
                            </span>
                          </Label>
                          <Switch
                            id="active-switch"
                            checked={isPublish_status}
                            onCheckedChange={() =>
                              setIsPublish_status(!isPublish_status)
                            }
                            aria-label="Toggle Active State"
                          />
                        </div>
                      </div>

                      {/* Error message  */}
                      <div>
                        {error.map((err, index) => (
                          <p key={index} className="text-red-500 text-[12px]">
                            {err}
                          </p>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="flex gap-3">
                    <Button type="submit" className="flex-1">
                      Save Changes
                    </Button>

                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button
                          variant="destructive"
                          className="flex items-center gap-2"
                        >
                          <Trash2 className="h-4 w-4" />
                          Delete Trigger
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you absolutely sure?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action cannot be undone. This will permanently
                            delete your trigger and remove your data from our
                            servers.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter className="m-2">
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction onClick={handleDelete}>
                            Continue
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <>
              <DialogHeader>
                <DialogTitle className="text-2xl font-bold text-[#1A1F2C]">
                  {title}
                </DialogTitle>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="flex items-center gap-4">
                  <Badge variant="outline" className={getTypeColor(type)}>
                    {type}
                  </Badge>
                  <Badge variant={isPublish_status ? "default" : "secondary"}>
                    {isPublish_status ? "Active" : "Inactive"}
                  </Badge>
                </div>
                <div className="flex items-center gap-4 text-sm text-gray-600">
                  <div className="flex items-center gap-2">
                    <Clock className="h-4 w-4" />
                    <span>{time}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Users className="h-4 w-4" />
                    <span>{participants.length} participants</span>
                  </div>
                </div>
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>

      <TriggerAnalytics
        open={analyticsOpen}
        onOpenChange={setAnalyticsOpen}
        triggerTitle={title}
        triggerId={id}
      />
    </>
  );
}
