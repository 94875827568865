import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Footer } from "./components/Footer";
import {
  Bell,
  Sun,
  Users,
  ThumbsUp,
  Activity,
  Smile,
  UserCheck,
  Heart,
  Zap,
  ArrowRight,
} from "lucide-react";

const features = [
  {
    title: "Send Reminders",
    description:
      "Keep everyone on track with personalized reminders for deadlines, meetings, and tasks.",
    icon: Bell,
    emoji: "💡",
  },
  {
    title: "Daily Stand-ups",
    description:
      "Streamline your daily stand-ups with structured updates and blockers tracking.",
    icon: Sun,
    emoji: "🌞",
  },
  {
    title: "Monthly 1-on-1",
    description:
      "Foster meaningful one-on-one conversations with your team members in a secure environment.",
    icon: Users,
    emoji: "🤝",
  },
  {
    title: "Retrospective",
    description:
      "Assess your sprint's progress and identify areas of improvement to ensure deliveries remain on track.",
    icon: ThumbsUp,
    emoji: "🤔",
  },
  {
    title: "Sprint Health Check",
    description:
      "Monitor project progress, risks, and blockers with daily updates to stay ahead of challenges.",
    icon: Activity,
    emoji: "💻",
  },
  {
    title: "Icebreaker",
    description:
      "Kickstart your week with engaging icebreaker questions that spark creativity and collaboration.",
    icon: Smile,
    emoji: "😊",
  },
  {
    title: "1-1 Meeting",
    description:
      "Make periodic one-on-one meetings impactful with structured questions for open communication.",
    icon: UserCheck,
    emoji: "🤝",
  },
  {
    title: "Well-being Check-in",
    description:
      "Build a supportive work culture by making mental health a priority with regular team updates.",
    icon: Heart,
    emoji: "🖐️",
  },
  {
    title: "Start from Scratch",
    description:
      "Create your own questions for a tailored check-in experience perfect for unique team workflows.",
    icon: Zap,
    emoji: "⚡",
  },
];

const Features = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-blue-50">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0.6))] -z-10" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
          <div className="text-center space-y-8">
            <h1 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600 tracking-tight">
              Features of Workfolio Bot
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
              {`Simplify your team's workflow and enhance collaboration with our
              thoughtfully designed features. Here's what you can do with our
              templates:`}
            </p>
            <Button
              onClick={() => navigate("/login")}
              size="lg"
              className="bg-gradient-to-r from-purple-600 to-blue-600 text-white shadow-lg hover:shadow-purple-500/30 transition-all duration-300 hover:scale-105"
            >
              Get Started Now
              <ArrowRight className="ml-2" />
            </Button>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <Card
              key={index}
              className="group relative overflow-hidden p-6 hover:shadow-xl transition-all duration-500 hover:scale-102 bg-white/80 backdrop-blur-sm animate-fade-in border-transparent hover:border-purple-100"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <div className="absolute inset-0 bg-gradient-to-br from-purple-50/50 to-blue-50/50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              <div className="relative flex items-start space-x-4">
                <div className="flex-shrink-0">
                  <div className="p-3 rounded-sm bg-gradient-to-br from-purple-100 to-blue-100 group-hover:from-purple-200 group-hover:to-blue-200 transition-colors duration-300">
                    <feature.icon className="w-6 h-6 text-purple-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2 flex items-center group-hover:text-purple-600 transition-colors duration-300">
                    {feature.title}
                    <span className="ml-2 text-2xl transform group-hover:scale-110 transition-transform duration-300">
                      {feature.emoji}
                    </span>
                  </h3>
                  <p className="text-gray-600 leading-relaxed group-hover:text-gray-700 transition-colors duration-300 text-start">
                    {feature.description}
                  </p>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <div className="text-center mt-20">
          <Button
            onClick={() => navigate("/login")}
            size="lg"
            className="bg-gradient-to-r from-purple-600 to-blue-600 text-white shadow-lg hover:shadow-purple-500/30 transition-all duration-300 hover:scale-105"
          >
            Start Using Workfolio Bot
            <ArrowRight className="ml-2" />
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Features;
