import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const FreePricing = () => {
  const navigate = useNavigate();
  return (
    <section className="py-20 px-4 bg-gray-50">
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">
          Start Using Workfolio Bot for Free
        </h2>
        <p className="text-xl text-gray-600 mb-8">
          Get started with our basic features at no cost. No credit card
          required.
        </p>
        <div className="glass-card p-8 rounded-lg mb-8 inline-block">
          <div className="text-6xl font-bold text-[#6b46c1] mb-4">$0</div>
          <div className="text-xl mb-6">Basic Plan</div>
          <ul className="text-left space-y-4 mb-8">
            <li className="flex items-center">
              <ArrowRight className="h-5 w-5 text-[#6b46c1] mr-2" />
              <span>Basic standup meetings</span>
            </li>
            <li className="flex items-center">
              <ArrowRight className="h-5 w-5 text-[#6b46c1] mr-2" />
              <span>Simple team updates</span>
            </li>
            <li className="flex items-center">
              <ArrowRight className="h-5 w-5 text-[#6b46c1] mr-2" />
              <span>Core integrations</span>
            </li>
          </ul>
          <Button
            size="lg"
            className="w-full  hover:bg-[#6b46c1]/90 bg-[#6b46c1]"
            onClick={() => navigate("/login")}
          >
            Get Started Free
            <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </div>
      </div>
    </section>
  );
};
