import { Check, ChartBar, Bell, Calendar } from "lucide-react";

export const Benefits = () => {
  return (
    <section className="py-20 px-4 bg-accent">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-12">
          Why Teams 💜 Workfolio Bot
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="glass-card p-6 rounded-sm text-center hover-lift">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-[#6b46c1]/10 mb-4">
              <Check className="h-6 w-6 text-[#6b46c1]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Never Miss Updates</h3>
            <p className="text-gray-600">
              Automated reminders ensure your team stays on track with regular
              check-ins
            </p>
          </div>

          <div className="glass-card p-6 rounded-lg text-center hover-lift">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-[#6b46c1]/10 mb-4">
              <ChartBar className="h-6 w-6 text-[#6b46c1]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Track Progress</h3>
            <p className="text-gray-600">
              Monitor team progress and identify bottlenecks before they become
              issues
            </p>
          </div>

          <div className="glass-card p-6 rounded-lg text-center hover-lift">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-[#6b46c1]/10 mb-4">
              <Bell className="h-6 w-6 text-[#6b46c1]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Smart Notifications</h3>
            <p className="text-gray-600">
              Get intelligent alerts about important updates and team activities
            </p>
          </div>

          <div className="glass-card p-6 rounded-lg text-center hover-lift">
            <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-[#6b46c1]/10 mb-4">
              <Calendar className="h-6 w-6 text-[#6b46c1]" />
            </div>
            <h3 className="text-xl font-semibold mb-2">Scheduled Check-ins</h3>
            <p className="text-gray-600">
              Automate routine check-ins and keep your team in sync effortlessly
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
