import CreateTriggerModal from "@/components/createTriggerModals/CreateTriggerModal";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { TriggerCard } from "@/components/TriggerCard";
import EmptyTemplate from "../EmptyTemplate";
import { Card, CardContent } from "@/components/ui/card";
import { Trigger } from "../types";
import { fetchTriggers } from "./api";
import { fetchData } from "./fetchData";

// TriggerCard Props is an interface that defines the properties of a trigger card
export interface TriggerCardProps {
  id: string;
  title: string;
  type: string;
  time: string;
  timeZone: string;
  frequency: string;
  days: string[];
  participants: string[];
  recipients: string[];
  content: string;
  questions: string[];
  status: string | "active" | "inactive";
  publish_status: boolean;
}

const Index = () => {
  // State for search query for filtering triggers
  const [searchQuery, setSearchQuery] = useState("");

  // State for fetched triggers
  const [yourTriggers, setYourTriggers] = useState<TriggerCardProps[]>([]);

  // Fetch triggers when the component mounts
  useEffect(() => {
    const loadTriggers = async () => {
      // fetchData is a function that fetches triggers from the API
      const triggers = await fetchData();

      // setYourTriggers is a function that sets the fetched triggers to the state
      setYourTriggers(triggers);
      console.log("triggers", triggers);
    };

    loadTriggers();
  }, []); // Only runs on mount

  const handleSetTriggers = (newTriggers: any) => {
    setYourTriggers(newTriggers);
  };

  // Filtering the triggers based on the search query
  const filteredTriggers = yourTriggers.filter((trigger) =>
    trigger.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-8 bg-[rgb(249,250,251)]">
      <div className="flex justify-between items-start mb-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Dashboard</h1>
          <p className="text-muted-foreground mt-1">
            Manage your automated triggers and team activities
          </p>
        </div>

        {/* CreateTriggerModal is a component that renders a modal for creating a new trigger */}
        <CreateTriggerModal setYourTriggers={handleSetTriggers} />
      </div>
      {/* If there are no triggers, render an empty template */}
      {filteredTriggers.length > 0 ? (
        <>
          <div className="flex gap-4 mb-8">
            {/* Input component for searching triggers */}
            <Input
              placeholder="Search triggers..."
              className="max-w-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Grid component for displaying triggers */}
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {filteredTriggers.map((trigger) => (
              <TriggerCard
                key={trigger.id}
                {...trigger}
                setYourTriggers={setYourTriggers}
              />
              // Pass setYourTriggers as a prop
            ))}
          </div>
        </>
      ) : (
        // If there are no triggers, render an empty template
        <Card className="bg-muted/50">
          <CardContent className="flex flex-col items-center justify-center py-16 text-center">
            <h2 className="text-xl font-semibold mb-2">{`No triggers yet`}</h2>
            <p className="text-muted-foreground mb-4">
              Create your first team triggers to start tracking team wellness.
            </p>
            {/* Button component for creating a new trigger */}
            <CreateTriggerModal setYourTriggers={handleSetTriggers} />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Index;
