import { useState, useEffect } from "react";
import { ReminderFields } from "./trigger-fields/ReminderFields";
import { TriggerForm } from "./trigger-fields/TriggerForm";
import {
  fetchChannels,
  fetchParticipants,
  getYourTriggerQuestion,
} from "./createTriggerModals/api";

interface ReminderFields {
  content: string;
  time: string;
  timeZone: string;
  frequency: string;
  days: string[];
  participants?: string[];
}

interface StandupFields {
  questions: string[];
  time?: string;
  timeZone?: string;
  days?: string[];
  frequency?: string;
  participants?: string[];
  responseChannel?: string[];
}

interface TriggerTypeFieldsProps {
  type: string;
  onFieldsChange: (fields: ReminderFields | StandupFields) => void;
  fieldValues: any; // change later
}

interface User {
  label: string;
  value: string;
}

interface Channel {
  label: string;
  value: string;
}

export function TriggerTypeFields({
  type,
  onFieldsChange,
  fieldValues,
}: TriggerTypeFieldsProps) {
  console.log("type", type);
  console.log("fieldValues", fieldValues);

  const [questions, setQuestions] = useState<string[]>([]);
  const [availableChannels, setAvailableChannels] = useState<Channel[]>([]);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);

  const defaultQuestions = {
    standup: [
      "How are you feeling?",
      "What did you complete in your previous workday?",
      "What are you going to do today?",
      "Do you have any blockers?",
    ],
    "well-being": [
      "Thinking about this past week: how productive do you feel you were?",
      "Do you feel you've had more wins or blocks this past week?",
    ],
    retrospective: [
      "What went well?",
      "What did you learn?",
      "What can be improved?",
    ],
    "sprint-health-check": [
      "How confident are you that you will complete the task for this sprint?",
    ],
    icebreaker: [
      "How was your weekend? Is there anything fun you'd like to share?",
    ],
    "1-1-meeting": [
      "How are you feeling, out of 5?",
      "What could improve this score?",
    ],
    "monthly-1-on-1": [
      "What was your highlight of your month?",
      "Any blockers I can help remove?",
    ],
    "start-from-scratch": [""],
  };

  const [reminderFields, setReminderFields] = useState<ReminderFields>({
    content: fieldValues.content || "",
    time: fieldValues.time || "",
    timeZone: fieldValues.timeZone || "",
    frequency: fieldValues.frequency || "",
    days: fieldValues.days || [],
    participants: fieldValues.participants || [],
  });

  const [fields, setFields] = useState<StandupFields>({
    questions: defaultQuestions[type as keyof typeof defaultQuestions] || [""],
    time: fieldValues.time || "",
    timeZone: fieldValues.timeZone || "",
    days: fieldValues.days || [],
    frequency: fieldValues.frequency || "daily",
    participants: fieldValues.participants || [],
    responseChannel: fieldValues.responseChannel || [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const channels = await fetchChannels();
      const users = await fetchParticipants();
      const triggerQuestions = await getYourTriggerQuestion(fieldValues.id);

      console.log("Fetched Questions:", triggerQuestions);

      setQuestions(triggerQuestions || []);

      const userList: User[] = users.map((user: any) => ({
        value: user.id,
        label: user.profile.real_name,
      }));

      const channelList: Channel[] = channels.map((channel: any) => ({
        value: channel.id,
        label: channel.name,
      }));

      setAvailableChannels(channelList);
      setAvailableUsers(userList);
    };

    fetchData();
  }, [fieldValues.id]);

  useEffect(() => {
    // Update questions when type changes
    setFields((prev) => ({
      ...prev,
      questions:
        questions.length > 0
          ? questions
          : defaultQuestions[type as keyof typeof defaultQuestions],
    }));
  }, [questions, type]); // Now depends on `questions` and `type`

  useEffect(() => {
    // Handle changing of type properly by resetting questions
    if (questions.length === 0) {
      setFields((prev) => ({
        ...prev,
        questions: defaultQuestions[type as keyof typeof defaultQuestions],
      }));
    }
  }, [type, questions]); // Trigger question updates on `type` change

  // useEffect(() => {
  //   if (questions.length > 0) {
  //     setFields((prev) => ({
  //       ...prev,
  //       questions,
  //     }));
  //   } else {
  //     setFields((prev) => ({
  //       ...prev,
  //       questions: defaultQuestions[type as keyof typeof defaultQuestions],
  //     }));
  //   }
  // }, [questions, type]);

  if (type === "reminder") {
    return (
      <ReminderFields
        fields={reminderFields}
        onFieldsChange={(updatedFields) => {
          setReminderFields(updatedFields);
          onFieldsChange(updatedFields);
        }}
        availableUsers={availableUsers}
      />
    );
  }

  return (
    <TriggerForm
      fields={fields}
      onFieldsChange={(updatedFields) => {
        setFields(updatedFields);
        onFieldsChange(updatedFields);
      }}
      availableUsers={availableUsers}
      availableChannels={availableChannels}
    />
  );
}
