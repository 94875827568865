import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Calendar } from "@/components/ui/calendar";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { format } from "date-fns";
import { StandupKanbanView } from "./StandupKanbanView";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";

interface StandupAnalyticsProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  triggers: Array<{ id: string; title: string }>;
}

const sampleData = {
  questions: [
    "What did you work on yesterday?",
    "What are you working on today?",
    "Do you have any roadblocks?",
  ],
  responses: {
    q1: [
      {
        user: "Sarah Chen",
        answer:
          "- Completed the user authentication flow refactoring\n- Fixed 3 critical bugs in the dashboard\n- Code review for the payment integration PR\n- Updated API documentation for new endpoints",
        time: "9:15 AM",
      },
      {
        user: "Michael Rodriguez",
        answer:
          "- Implemented real-time notifications feature\n- Set up WebSocket connections for live updates\n- Optimized database queries for the analytics dashboard\n- Pair programming session with the junior dev",
        time: "9:20 AM",
      },
      {
        user: "Emily Thompson",
        answer:
          "- Finished the mobile responsive design for the landing page\n- Created new UI components for the design system\n- Fixed cross-browser compatibility issues\n- Updated unit tests for the frontend",
        time: "9:25 AM",
      },
      {
        user: "Alex Kumar",
        answer:
          "- Deployed new microservice to production\n- Monitored system performance after deployment\n- Updated CI/CD pipeline configurations\n- Documentation for the deployment process",
        time: "9:30 AM",
      },
    ],
    q2: [
      {
        user: "Sarah Chen",
        answer:
          "- Starting work on the new analytics dashboard\n- Planning to implement data visualization components\n- Setting up integration tests for the auth system\n- Meeting with the design team for new features",
        time: "9:15 AM",
      },
      {
        user: "Michael Rodriguez",
        answer:
          "- Implementing message queue system\n- Adding retry mechanism for failed operations\n- Performance testing of the notification system\n- Code review for team members' PRs",
        time: "9:20 AM",
      },
      {
        user: "Emily Thompson",
        answer:
          "- Working on the new checkout flow UI\n- Implementing form validation\n- Creating animations for user interactions\n- Updating Storybook documentation",
        time: "9:25 AM",
      },
      {
        user: "Alex Kumar",
        answer:
          "- Setting up monitoring for the new microservice\n- Implementing auto-scaling policies\n- Working on disaster recovery procedures\n- Meeting with the security team",
        time: "9:30 AM",
      },
    ],
    q3: [
      {
        user: "Sarah Chen",
        answer:
          "- Need access to the production metrics API\n- Waiting for design team's feedback on the new dashboard layout\n- Some test environments are unstable",
        time: "9:15 AM",
      },
      {
        user: "Michael Rodriguez",
        answer:
          "- AWS service limits need to be increased for the queue system\n- Pending security review for WebSocket implementation\n- Need more detailed requirements for error handling",
        time: "9:20 AM",
      },
      {
        user: "Emily Thompson",
        answer:
          "- Waiting for backend API endpoints to be ready\n- Need clarification on mobile-specific requirements\n- Performance issues with some third-party components",
        time: "9:25 AM",
      },
      {
        user: "Alex Kumar",
        answer:
          "- DevOps team bandwidth for security review\n- Need to coordinate with other teams for integration testing\n- Some monitoring tools licenses expired",
        time: "9:30 AM",
      },
    ],
  },
};

export function StandupAnalytics({
  open,
  onOpenChange,
  triggers,
}: StandupAnalyticsProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    new Date()
  );
  const [selectedTrigger, setSelectedTrigger] = useState<string>("");

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[80%]   max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-[#1A1F2C]">
            Standup Analytics
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          <div className="flex items-center gap-4">
            <Select value={selectedTrigger} onValueChange={setSelectedTrigger}>
              <SelectTrigger className="w-[240px]">
                <SelectValue placeholder="Select trigger" />
              </SelectTrigger>
              <SelectContent>
                {triggers.map((trigger) => (
                  <SelectItem key={trigger.id} value={trigger.id.toString()}>
                    {trigger.title}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant={"outline"}
                  className={cn(
                    "w-[240px] justify-start text-left font-normal",
                    !selectedDate && "text-muted-foreground"
                  )}
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {selectedDate ? format(selectedDate, "PPP") : "Select date"}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={selectedDate}
                  onSelect={setSelectedDate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>

            <Button
              variant="outline"
              onClick={() => setSelectedDate(new Date())}
              size="sm"
            >
              Today
            </Button>
          </div>

          {selectedTrigger && <StandupKanbanView data={sampleData} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}
