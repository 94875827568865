import { Button } from "@/components/ui/button";
import { Plus, BarChart2 } from "lucide-react";
import { Link } from "react-router-dom";
import { Input } from "@/components/ui/input";
import { useState, useEffect } from "react";
import { TriggerCard } from "@/components/TriggerCard";
import { StandupAnalytics } from "@/components/StandupAnalytics";
import EmptyTemplate from "../pages/EmptyTemplate";
import { fetchData } from "./home/fetchData";

export default function Standups() {
  const [searchQuery, setSearchQuery] = useState("");
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const [sampleTriggers, setSampleTriggers] = useState<any[]>([]); // State for fetched triggers
  const [loading, setLoading] = useState(true); // Optional: Track loading state

  // Fetch triggers when the component mounts
  useEffect(() => {
    fetchData()
      .then((data) => {
        // Filter standup triggers
        const standupTriggers = data.filter(
          (trigger) => trigger.type === "standup"
        );
        // Set sample triggers state
        setSampleTriggers(standupTriggers);
      })
      .catch((error) => {
        console.error("Error fetching triggers:", error);
      })
      .finally(() => setLoading(false)); // Set loading to false when done
  }, []);

  const filteredTriggers = sampleTriggers.filter((trigger) =>
    trigger.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="p-8 bg-[rgb(249,250,251)]">
      <div className="flex justify-between items-start mb-8">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Standups</h1>
          <p className="text-muted-foreground mt-1">
            Manage your team standups and track responses
          </p>
        </div>
        <div className="flex gap-3">
          <Button
            variant="outline"
            className="gap-2"
            onClick={() => setAnalyticsOpen(true)}
          >
            <BarChart2 className="h-4 w-4" />
            Analytics
          </Button>
          <Button
            className="gap-2 bg-purple-500 hover:bg-purple-600 text-white"
            size="default"
            asChild
          >
            <Link to="/create-standup">
              <Plus className="h-4 w-4" />
              Create Standup
            </Link>
          </Button>
        </div>
      </div>

      {loading ? (
        <p>Loading triggers...</p>
      ) : filteredTriggers.length > 0 ? (
        <>
          <div className="flex gap-4 mb-8">
            <Input
              placeholder="Search standups..."
              className="max-w-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {filteredTriggers.map((trigger) => (
              <TriggerCard key={trigger.id} {...trigger} />
            ))}
          </div>
        </>
      ) : (
        <EmptyTemplate name="Standup" link="/create-standup" />
      )}
      <StandupAnalytics
        open={analyticsOpen}
        onOpenChange={setAnalyticsOpen}
        triggers={sampleTriggers}
      />
    </div>
  );
}
