export const invoices = [
  {
    id: 1,
    date: "March 2024",
    amount: "$29.00",
    status: "Paid",
    downloadUrl: "#",
  },
  {
    id: 2,
    date: "February 2024",
    amount: "$29.00",
    status: "Paid",
    downloadUrl: "#",
  },
  {
    id: 3,
    date: "January 2024",
    amount: "$29.00",
    status: "Paid",
    downloadUrl: "#",
  },
];

export const freePlanFeatures = [
  "2 free trigger creation",
  "Access to basic triggers",
  "1,000 trigger hits per month",
  "Unlimited users",
  "Basic support",
];

export const proPlanFeatures = [
  "Access to all triggers",
  "Unlimited trigger creation",
  "10,000 trigger hits per month",
  "Unlimited users",
  "Priority support",
];