import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { getEventBadgeColor, getEventLabel } from "./CommandCard";
import { WorkEvent } from "./types";

interface EventsTableProps {
  events: WorkEvent[];
  showEventType?: boolean;
}

export const EventsTable = ({ events, showEventType = true }: EventsTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Team Member</TableHead>
          {showEventType && <TableHead>Event</TableHead>}
          <TableHead>Time</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((event) => (
          <TableRow key={event.id}>
            <TableCell>{event.userName}</TableCell>
            {showEventType && (
              <TableCell>
                <Badge className={getEventBadgeColor(event.eventType)}>
                  {getEventLabel(event.eventType)}
                </Badge>
              </TableCell>
            )}
            <TableCell>
              {format(event.timestamp, "MMM d, yyyy HH:mm")}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};