import { Github, Twitter } from "lucide-react";
import { Button } from "@/components/ui/button";

export const Footer = () => {
  return (
    <footer className="bg-accent py-12 ">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-left">
          <div className="space-y-4">
            <h3 className="font-bold text-lg">Workfolio Bot</h3>
            <p className="text-sm text-gray-600">
              Your all-in-one workspace assistant for better team collaboration.
            </p>
          </div>

          <div className="space-y-4">
            <h4 className="font-bold">Product</h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="/features"
                  className="text-sm text-gray-600 hover:text-[#6b46c1]"
                >
                  Features
                </a>
              </li>
              <li>
                <a
                  href="/pricing"
                  className="text-sm text-gray-600 hover:text-[#6b46c1]"
                >
                  Pricing
                </a>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h4 className="font-bold">Resources</h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="/blog"
                  className="text-sm text-gray-600 hover:text-[#6b46c1]"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="/support"
                  className="text-sm text-gray-600 hover:text-[#6b46c1]"
                >
                  Support
                </a>
              </li>
            </ul>
          </div>

          <div className="space-y-4">
            <h4 className="font-bold">Company</h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="/about"
                  className="text-sm text-gray-600 hover:text-[#6b46c1]"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-sm text-gray-600 hover:text-[#6b46c1]"
                >
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-200 mt-12 pt-8 text-center text-sm text-gray-600">
          <p>
            &copy; {new Date().getFullYear()} Workfolio Bot. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
