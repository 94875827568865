import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { PlanFeatures } from "./PlanFeatures";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface PricingPlanProps {
  title: string;
  price: string;
  features: string[];
  currentPlan: "free" | "pro";
  isPro: boolean;
  onUpgrade?: () => void;
  onDowngrade?: () => void;
}

export function PricingPlan({
  title,
  price,
  features,
  currentPlan,
  isPro,
  onUpgrade,
  onDowngrade,
}: PricingPlanProps) {
  const isCurrentPlan = (isPro && currentPlan === "pro") || (!isPro && currentPlan === "free");
  const borderClass = isPro ? "border-purple-500" : "";
  const badgeColor = isPro ? "bg-purple-500" : "bg-green-500";

  return (
    <Card className={`relative overflow-hidden ${borderClass}`}>
      {isCurrentPlan && (
        <div className={`absolute top-0 right-0 ${badgeColor} text-white px-4 py-1 rounded-bl-lg text-sm`}>
          Your current plan
        </div>
      )}
      <CardHeader>
        <CardTitle>
          <div className="text-2xl font-bold">{title}</div>
          <div className="text-3xl font-bold mt-2">{price}</div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <PlanFeatures features={features} />
      </CardContent>
      <CardFooter>
        {isPro && currentPlan === "pro" ? (
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button className="w-full bg-purple-500 hover:bg-purple-600">
                Downgrade to free
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                <AlertDialogDescription>
                  Downgrading to the free plan will limit your access to certain features. You can always upgrade back to Pro later.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Cancel</AlertDialogCancel>
                <AlertDialogAction onClick={onDowngrade}>
                  Confirm Downgrade
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        ) : (
          <Button
            className={`w-full ${isPro ? "bg-purple-500 hover:bg-purple-600" : ""}`}
            variant={isPro ? "default" : "outline"}
            disabled={currentPlan === "free" && !isPro}
            onClick={isPro ? onUpgrade : undefined}
          >
            {currentPlan === "free" && !isPro
              ? "Current Plan"
              : isPro
              ? "Upgrade to Pro plan"
              : "Get Started"}
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}