import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import {
  Home,
  Bell,
  Users,
  Settings,
  CreditCard,
  BarChart,
  UserPlus,
  MessageSquare,
} from "lucide-react";
import { Link } from "react-router-dom";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Menu } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";

const mainMenuItems = [
  { title: "Dashboard", icon: Home, url: "/home" },
  { title: "Analytics", icon: BarChart, url: "/analytics" },
  { title: "Standups", icon: Bell, url: "/standups" },
  { title: "Reminders", icon: Bell, url: "/reminders" },
  { title: "Team Check-ins", icon: Users, url: "/team-check-ins" },
  { title: "Slack Commands", icon: MessageSquare, url: "/slack-commands" },
];

const settingsMenuItems = [
  { title: "Team Members", icon: UserPlus, url: "/team-members" },
  { title: "Settings", icon: Settings, url: "/settings" },
  { title: "Billing", icon: CreditCard, url: "/billing" },
];

const SidebarContents = () => (
  <>
    <div className="p-4 border-b border-sidebar-border">
      <Link to="/home" className="flex items-center gap-2">
        <span className="font-bold text-xl text-sidebar-primary flex items-center gap-2">
          <img
            src="/logo-v2.png"
            alt="Workfolio Logo"
            className={`transition-all duration-300 h-10 w-10 `}
          />
          Workfolio Bot
        </span>
      </Link>
    </div>
    <SidebarContent>
      <SidebarGroup>
        <SidebarGroupLabel>Navigation</SidebarGroupLabel>
        <SidebarGroupContent>
          <SidebarMenu>
            {mainMenuItems.map((item) => (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton asChild>
                  <Link to={item.url} className="flex items-center gap-3">
                    <item.icon className="h-4 w-4" />
                    <span>{item.title}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>

      <SidebarGroup>
        <SidebarGroupContent>
          <SidebarMenu>
            {settingsMenuItems.map((item) => (
              <SidebarMenuItem key={item.title}>
                <SidebarMenuButton asChild>
                  <Link to={item.url} className="flex items-center gap-3">
                    <item.icon className="h-4 w-4" />
                    <span>{item.title}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            ))}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
    </SidebarContent>
  </>
);

export function AppSidebar() {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="md:hidden fixed left-4 top-4 z-40"
          >
            <Menu className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="p-0 w-[280px]">
          <div className="h-full flex flex-col">
            <SidebarContents />
          </div>
        </SheetContent>
      </Sheet>
    );
  }

  return (
    <Sidebar>
      <div className="h-full flex flex-col">
        <SidebarContents />
      </div>
    </Sidebar>
  );
}
