interface RenewalNoticeProps {
  nextRenewalDate: string;
  nextRenewalAmount: string;
}

export function RenewalNotice({ nextRenewalDate, nextRenewalAmount }: RenewalNoticeProps) {
  return (
    <div className="mb-8">
      <div className="bg-sidebar-accent dark:bg-sidebar-accent border border-sidebar-border rounded-lg p-4 space-y-3">
        <div className="flex items-center space-x-2">
          <div className="h-2 w-2 rounded-full bg-sidebar-primary animate-pulse" />
          <h3 className="text-sm font-medium text-sidebar-primary">Upcoming Renewal</h3>
        </div>
        <div className="space-y-2">
          <p className="text-base font-semibold">
            Your plan will be renewed on <span className="text-sidebar-primary">{nextRenewalDate}</span>
          </p>
          <p className="text-sm text-muted-foreground">
            Next renewal amount: <span className="font-medium text-sidebar-primary">{nextRenewalAmount}</span>
          </p>
        </div>
      </div>
    </div>
  );
}