import { Settings, Users, LineChart } from "lucide-react";

export const HowItWorks = () => {
  const steps = [
    {
      icon: Settings,
      title: "Set up your workflow",
      description:
        "Set up questions, schedules, and channels within Slack. Workfolio Bot is customizable to your team's unique needs.",
    },
    {
      icon: Users,
      title: "Engage Your Team",
      description:
        "The bot interacts with team members seamlessly. Whether it's collecting standup responses, sending reminders, or distributing surveys, Workfolio Bot handles it all.",
    },
    {
      icon: LineChart,
      title: "Review and Act",
      description:
        "Receive all responses directly in your Slack channel. Take immediate action, identify blockers, and celebrate wins – all without leaving Slack.",
    },
  ];

  return (
    <section className="py-24 bg-gradient-to-b from-white via-accent/30 to-white relative overflow-hidden">
      <div className="absolute inset-0 bg-grid-black/[0.02] -z-10" />

      <div className="container mx-auto px-4">
        <div className="text-center max-w-2xl mx-auto mb-20">
          <h2 className="text-4xl font-bold mb-6">How It Works</h2>
          <p className="text-xl text-gray-600">
            Get started with Workfolio Bot in three simple steps
          </p>
        </div>

        <div className="relative">
          {/* Connection Line */}
          {/* <div className="hidden lg:block absolute top-1/2 left-[20%] right-[20%] h-0.5 bg-gradient-to-r from-[#6b46c1]/20 via-[#6b46c1]/40 to-[#6b46c1]/20 -translate-y-1/2" /> */}

          <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto relative z-10">
            {steps.map((step, index) => (
              <div key={index} className="group scroll-reveal">
                <div
                  className="glass-card hover:shadow-xl transition-all duration-300 p-8 rounded-2xl 
                              relative flex flex-col items-center text-center
                              before:absolute before:inset-0 before:rounded-2xl before:border before:border-white/20
                              before:transition-colors before:duration-300
                              hover:before:border-[#6b46c1]/30"
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-[#6b46c1]/5 rounded-full blur-xl group-hover:bg-[#6b46c1]/10 transition-colors duration-300" />
                    <div
                      className="relative bg-white w-20 h-20 rounded-full flex items-center justify-center mb-6 
                                  shadow-sm group-hover:shadow-md transition-shadow duration-300
                                  border border-[#6b46c1]/10 group-hover:border-[#6b46c1]/20"
                    >
                      <step.icon className="w-8 h-8 text-[#6b46c1] group-hover:scale-110 transition-transform duration-300" />
                    </div>
                  </div>

                  <h3 className="text-xl font-semibold mb-4 group-hover:text-[#6b46c1] transition-colors duration-300">
                    Step {index + 1}: {step.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed text-sm">
                    {step.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
