import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Edit2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

type Role = "team member" | "manager" | "admin";

interface TeamMember {
  id: number;
  name: string;
  email: string;
  role: Role;
}

const initialMembers: TeamMember[] = [
  { id: 1, name: "John Doe", email: "john@example.com", role: "admin" },
  { id: 2, name: "Jane Smith", email: "jane@example.com", role: "manager" },
  { id: 3, name: "Bob Wilson", email: "bob@example.com", role: "team member" },
];

export default function TeamMembers() {
  const [members, setMembers] = useState<TeamMember[]>(initialMembers);
  const [editingMember, setEditingMember] = useState<TeamMember | null>(null);
  const { toast } = useToast();

  const handleEdit = (member: TeamMember) => {
    setEditingMember({ ...member });
  };

  const handleSave = () => {
    if (editingMember) {
      setMembers(members.map(m => m.id === editingMember.id ? editingMember : m));
      setEditingMember(null);
      toast({
        title: "Success",
        description: "Team member updated successfully",
      });
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Team Members</h1>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {members.map((member) => (
            <TableRow key={member.id}>
              <TableCell>{member.name}</TableCell>
              <TableCell>{member.email}</TableCell>
              <TableCell className="capitalize">{member.role}</TableCell>
              <TableCell>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => handleEdit(member)}
                    >
                      <Edit2 className="h-4 w-4" />
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Edit Team Member</DialogTitle>
                    </DialogHeader>
                    <div className="space-y-4 py-4">
                      <div className="space-y-2">
                        <label>Name</label>
                        <Input
                          value={editingMember?.name}
                          onChange={(e) =>
                            setEditingMember(prev => prev ? { ...prev, name: e.target.value } : null)
                          }
                        />
                      </div>
                      <div className="space-y-2">
                        <label>Email</label>
                        <Input
                          type="email"
                          value={editingMember?.email}
                          onChange={(e) =>
                            setEditingMember(prev => prev ? { ...prev, email: e.target.value } : null)
                          }
                        />
                      </div>
                      <div className="space-y-2">
                        <label>Role</label>
                        <Select
                          value={editingMember?.role}
                          onValueChange={(value: Role) =>
                            setEditingMember(prev => prev ? { ...prev, role: value } : null)
                          }
                        >
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="team member">Team Member</SelectItem>
                            <SelectItem value="manager">Manager</SelectItem>
                            <SelectItem value="admin">Admin</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <Button className="w-full" onClick={handleSave}>
                        Save Changes
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}