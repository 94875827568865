export const validateFields = (
  selectedTriggerType: string,
  fieldValues: {
    content?: string;
    time?: string;
    timeZone?: string;
    frequency?: string;
    days?: string[];
    responseChannel?: string[];
    participants?: string[];
    questions?: string[];
  }
): string[] => {
  const errors: string[] = [];

  if (selectedTriggerType === "reminder") {
    if (!fieldValues.content) errors.push("Content is required");
    if (!fieldValues.time) errors.push("Time is required");
    if (!fieldValues.timeZone) errors.push("Timezone is required");
    if (!fieldValues.frequency) errors.push("Frequency is required");
    if (!fieldValues.days?.length) errors.push("Days are required");
  } else {
    if (!fieldValues.time) errors.push("Time is required");
    if (!fieldValues.timeZone) errors.push("Timezone is required");
    if (!fieldValues.days?.length) errors.push("Days are required");
    if (!fieldValues.responseChannel?.length)
      errors.push("At least one channel is required");
    if (!fieldValues.participants?.length)
      errors.push("At least one participant is required");
    if (!fieldValues.questions?.length)
      errors.push("At least one question is required");
  }

  return errors;
};
