import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { UsageStats } from "@/components/billing/UsageStats";
import { PricingPlan } from "@/components/billing/PricingPlan";
import { BillingHistory } from "@/components/billing/BillingHistory";
import { RenewalNotice } from "@/components/billing/RenewalNotice";
import { invoices, freePlanFeatures, proPlanFeatures } from "@/data/billingData";

export default function Billing() {
  const [currentPlan, setCurrentPlan] = useState<"free" | "pro">("pro");
  const { toast } = useToast();

  const handleDowngrade = () => {
    setCurrentPlan("free");
    toast({
      title: "Plan Changed",
      description: "You have been successfully downgraded to the Free plan.",
    });
  };

  const handleUpgrade = () => {
    setCurrentPlan("pro");
    toast({
      title: "Plan Changed",
      description: "You have been successfully upgraded to the Pro plan.",
    });
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-8">Billing Plans</h1>

      <UsageStats currentPlan={currentPlan} />

      {currentPlan === "pro" && (
        <RenewalNotice 
          nextRenewalDate="Jan 23, 2025"
          nextRenewalAmount="$29.00"
        />
      )}
      
      <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        <PricingPlan
          title="Free"
          price="$0/month"
          features={freePlanFeatures}
          currentPlan={currentPlan}
          isPro={false}
        />
        <PricingPlan
          title="Pro"
          price="$29/month"
          features={proPlanFeatures}
          currentPlan={currentPlan}
          isPro={true}
          onUpgrade={handleUpgrade}
          onDowngrade={handleDowngrade}
        />
      </div>

      {currentPlan === "pro" && <BillingHistory invoices={invoices} />}
    </div>
  );
}