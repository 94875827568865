import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Trash2, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useEffect, useState, useMemo } from "react";
import MultipleSelector from "@/components/ui/multi-selector"; // Assuming you have this component
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup,
  SelectLabel,
} from "@/components/ui/select";

interface StandupFields {
  questions: string[];
  time?: string;
  timeZone?: string;
  days?: string[];
  frequency?: string;
  responseChannel?: string[];
  participants?: string[];
}

const timeZones = {
  "North America": [
    { value: "est", label: "Eastern Standard Time (EST)" },
    { value: "cst", label: "Central Standard Time (CST)" },
    { value: "mst", label: "Mountain Standard Time (MST)" },
    { value: "pst", label: "Pacific Standard Time (PST)" },
    { value: "akst", label: "Alaska Standard Time (AKST)" },
    { value: "hst", label: "Hawaii Standard Time (HST)" },
  ],
  "Europe & Africa": [
    { value: "gmt", label: "Greenwich Mean Time (GMT)" },
    { value: "cet", label: "Central European Time (CET)" },
    { value: "eet", label: "Eastern European Time (EET)" },
    { value: "west", label: "Western European Summer Time (WEST)" },
    { value: "cat", label: "Central Africa Time (CAT)" },
    { value: "eat", label: "East Africa Time (EAT)" },
  ],
  Asia: [
    { value: "msk", label: "Moscow Time (MSK)" },
    { value: "ist", label: "India Standard Time (IST)" },
    { value: "cst_china", label: "China Standard Time (CST)" },
    { value: "jst", label: "Japan Standard Time (JST)" },
    { value: "kst", label: "Korea Standard Time (KST)" },
    { value: "ist_indonesia", label: "Indonesia Central Standard Time (WITA)" },
  ],
  "Australia & Pacific": [
    { value: "awst", label: "Australian Western Standard Time (AWST)" },
    { value: "acst", label: "Australian Central Standard Time (ACST)" },
    { value: "aest", label: "Australian Eastern Standard Time (AEST)" },
    { value: "nzst", label: "New Zealand Standard Time (NZST)" },
    { value: "fjt", label: "Fiji Time (FJT)" },
  ],
  "South America": [
    { value: "art", label: "Argentina Time (ART)" },
    { value: "bot", label: "Bolivia Time (BOT)" },
    { value: "brt", label: "Brasilia Time (BRT)" },
    { value: "clt", label: "Chile Standard Time (CLT)" },
  ],
};

interface StandupFieldsProps {
  fields: StandupFields;
  onFieldsChange: (fields: StandupFields) => void;
  availableUsers: { value: string; label: string }[]; // For participants
  availableChannels: { value: string; label: string }[]; // For channels
}

export function TriggerForm({
  fields,
  onFieldsChange,
  availableUsers,
  availableChannels,
}: StandupFieldsProps) {
  const [newQuestion, setNewQuestion] = useState("");
  const [selectedDays, setSelectedDays] = useState<string[]>(fields.days || []);

  useEffect(() => {
    console.log("fields", fields);
    onFieldsChange({ ...fields });
  }, []);

  const handleQuestionChange = (index: number, value: string) => {
    const updatedQuestions = [...fields.questions];
    updatedQuestions[index] = value;
    onFieldsChange({ ...fields, questions: updatedQuestions });
  };

  const handleQuestionAdd = () => {
    if (newQuestion.trim()) {
      const updatedQuestions = [...fields.questions, newQuestion.trim()];
      onFieldsChange({ ...fields, questions: updatedQuestions });
      setNewQuestion("");
    }
  };

  const handleQuestionRemove = (index: number) => {
    const updatedQuestions = fields.questions.filter((_, i) => i !== index);
    onFieldsChange({ ...fields, questions: updatedQuestions });
  };

  const toggleDay = (day: string) => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter((d) => d !== day)
      : [...selectedDays, day];
    setSelectedDays(updatedDays);
    onFieldsChange({ ...fields, days: updatedDays });
  };

  const frequencies = ["Daily", "Weekly", "Bi-weekly", "Monthly"];
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [timeZone, setTimeZone] = useState("");

  const filteredTimeZones: Record<string, { value: string; label: string }[]> =
    useMemo(() => {
      return Object.entries(timeZones).reduce((acc: any, [region, zones]) => {
        const filteredZones = zones.filter((zone) =>
          zone.label.toLowerCase().includes(timeZone.toLowerCase())
        );
        if (filteredZones.length > 0) acc[region] = filteredZones;
        return acc;
      }, {} as typeof timeZones);
    }, [timeZone]);

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div>
          <Label>Time</Label>
          <Input
            type="time"
            value={fields.time || ""}
            onChange={(e) =>
              onFieldsChange({ ...fields, time: e.target.value })
            }
            className="bg-white"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="timeZone">Timezone</Label>
          <Select
            value={fields.timeZone || ""}
            onValueChange={(timeZone) =>
              onFieldsChange({ ...fields, timeZone })
            }
          >
            <SelectTrigger className="w-full">
              <SelectValue
                placeholder={fields.timeZone || "Select a timeZone"}
              />
            </SelectTrigger>
            <SelectContent className="max-h-[320px] overflow-auto">
              <div className="p-2">
                <Input
                  placeholder="Search time zones..."
                  value={timeZone || ""}
                  onChange={(e) => setTimeZone(e.target.value)}
                  className="w-full mb-2"
                />
              </div>
              {Object.entries(filteredTimeZones).map(([region, zones]) => (
                <SelectGroup key={region}>
                  <SelectLabel>{region}</SelectLabel>
                  {zones.map((zone) => (
                    <SelectItem key={zone.value} value={zone.value}>
                      {zone.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label>Days</Label>
          <div className="flex flex-wrap gap-2 mt-2">
            {daysOfWeek.map((day) => (
              <Button
                key={day}
                type="button"
                variant={
                  selectedDays.includes(day.toLowerCase())
                    ? "default"
                    : "outline"
                }
                onClick={() => toggleDay(day.toLowerCase())}
                className={
                  selectedDays.includes(day.toLowerCase())
                    ? "bg-[#9b87f5] hover:bg-[#7E69AB] text-white"
                    : "bg-[#F2FCE2] text-[#8E9196] hover:bg-[#D6BCFA]"
                }
              >
                {day.slice(0, 3)}
              </Button>
            ))}
          </div>
        </div>

        <div>
          <Label>Frequency</Label>
          <select
            value={fields.frequency || ""}
            onChange={(e) =>
              onFieldsChange({ ...fields, frequency: e.target.value })
            }
            className="bg-white border rounded p-2 w-full"
          >
            <option value="">Select frequency</option>
            {frequencies.map((freq) => (
              <option key={freq} value={freq.toLowerCase()}>
                {freq}
              </option>
            ))}
          </select>
        </div>

        <div>
          <Label>Post Response To</Label>
          <MultipleSelector
            options={availableChannels}
            value={availableChannels.filter((option) =>
              fields.responseChannel?.includes(option.value)
            )}
            onChange={(selectedOptions) => {
              const responseChannel = selectedOptions.map((opt) => opt.value);
              onFieldsChange({ ...fields, responseChannel });
            }}
            placeholder="Select channels"
          />
        </div>

        <div>
          <Label>Select Participants</Label>
          <MultipleSelector
            options={availableUsers}
            value={availableUsers.filter((option) =>
              fields.participants?.includes(option.value)
            )}
            onChange={(selectedOptions) => {
              const participants = selectedOptions.map((opt) => opt.value);
              onFieldsChange({ ...fields, participants });
            }}
            placeholder="Select participants"
          />
        </div>

        <div>
          <Label className="text-xl font-semibold mb-4">Questions</Label>
          <div className="space-y-3 mt-4">
            {fields.questions.map((question, index) => (
              <div
                key={index}
                className="flex items-start gap-2 bg-white rounded-lg p-4 shadow-sm border border-[#D6BCFA] group hover:border-[#9b87f5] transition-colors"
              >
                <span className="text-[#8E9196] mr-2 mt-2">{index + 1}.</span>
                <Textarea
                  value={question}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  className="border-none bg-transparent shadow-none focus-visible:ring-0 text-[#1A1F2C] resize-none"
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => handleQuestionRemove(index)}
                  className="h-8 w-8 opacity-0 group-hover:opacity-100 transition-opacity mt-2"
                >
                  <Trash2 className="h-4 w-4 text-red-500" />
                </Button>
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-start gap-2">
          <div className="relative flex-1">
            <Textarea
              placeholder="Type your question and press Enter..."
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleQuestionAdd();
                }
              }}
              className="bg-white pr-10 min-h-[100px] resize-y"
            />
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={handleQuestionAdd}
              className="absolute right-2 top-2"
              disabled={!newQuestion.trim()}
            >
              <Plus className="h-4 w-4 text-[#9b87f5]" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
