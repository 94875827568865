import { PricingMenu } from "./components/PricingMenu";
import { ProPlanTestimonials } from "./components/ProPlanTestimonials";
import { PricingFAQ } from "./components/PricingFAQ";
import { Footer } from "./components/Footer";

const Pricing = () => {
  return (
    <main className="min-h-screen">
      <PricingMenu />
      <ProPlanTestimonials />
      <PricingFAQ />
      <Footer />
    </main>
  );
};

export default Pricing;
