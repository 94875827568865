import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { Link } from "react-router-dom";
import { TriggerCard } from "@/components/TriggerCard";
import EmptyTemplate from "./EmptyTemplate";
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { fetchData } from "./home/fetchData";

export default function Reminders() {
  const [sampleReminders, setSampleReminders] = useState<any[]>([]); // State for fetched triggers
  const [loading, setLoading] = useState(true); // Optional: Track loading state
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch triggers when the component mounts
  useEffect(() => {
    fetchData()
      .then((data) => {
        // Filter standup triggers
        const reminderTriggers = data.filter(
          (trigger) => trigger.type === "reminder"
        );
        // Set sample triggers state
        setSampleReminders(reminderTriggers);
      })
      .catch((error) => {
        console.error("Error fetching triggers:", error);
      })
      .finally(() => setLoading(false)); // Set loading to false when done
  }, []);

  const filteredReminders = sampleReminders.filter((reminders) =>
    reminders.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container mx-auto p-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Reminders</h1>
        <Button
          asChild
          className="gap-2 bg-purple-500 hover:bg-purple-600 text-white"
          size="default"
        >
          <Link to="/create-reminder" className="flex items-center gap-2">
            <Plus className="h-4 w-4" />
            Create Reminder
          </Link>
        </Button>
      </div>
      {sampleReminders.length > 0 ? (
        <>
          <div className="flex gap-4 mb-8">
            <Input
              placeholder="Search reminders..."
              className="max-w-sm"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {filteredReminders.map((reminder, index) => (
              <TriggerCard key={reminder.id} {...reminder} />
            ))}
          </div>
        </>
      ) : (
        <EmptyTemplate name="Reminder" link="/create-reminder" />
      )}
    </div>
  );
}
