import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";

interface Response {
  user: string;
  answer: string;
  time: string;
}

interface KanbanColumnProps {
  title: string;
  responses: Response[];
}

const KanbanColumn = ({ title, responses }: KanbanColumnProps) => (
  <div className="flex-1 min-w-[300px] max-w-[350px]">
    <h3 className="font-semibold mb-3">{title}</h3>
    <ScrollArea className="h-[calc(100vh-300px)]">
      <div className="space-y-3 pr-4">
        {responses.map((response, index) => (
          <Card key={index} className="p-3">
            <div className="space-y-2">
              <div className="font-medium text-sm">{response.user}</div>
              <p className="text-sm text-muted-foreground">{response.answer}</p>
              <div className="text-xs text-muted-foreground">{response.time}</div>
            </div>
          </Card>
        ))}
      </div>
    </ScrollArea>
  </div>
);

interface StandupKanbanViewProps {
  data: {
    questions: string[];
    responses: {
      [key: string]: Response[];
    };
  };
}

export function StandupKanbanView({ data }: StandupKanbanViewProps) {
  return (
    <div className="flex gap-6 overflow-x-auto pb-4">
      {data.questions.map((question, index) => (
        <KanbanColumn
          key={index}
          title={question}
          responses={data.responses[`q${index + 1}`] || []}
        />
      ))}
    </div>
  );
}